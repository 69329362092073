import { React, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import SalesInvoiceTabView from '../../Common/ViewPages/SalesInvoice/SalesInvoiceTabView';
import SalesDeliveryTabView from 'module/Common/ViewPages/SalesDelivery/SalesDeliveryTabView';
import PurchaseInvoiceTabView from 'module/Common/ViewPages/PurchaseInvoice/PurchaseInvoiceTabView';
import PurchaseDeliveryTabView from 'module/Common/ViewPages/PurchaseDelivery/PurchaseDeliveryTabView';
import CustomerReceiptTabView from 'module/Common/ViewPages/CustomerReceipt/CustomerReceiptTabView';
import PaymentVoucherTabView from 'module/Common/ViewPages/PaymentVoucher/PaymentVoucherTabView';
import JournalVoucherTabView from 'module/Common/ViewPages/JournalVoucher/JournalVoucherTabView';
import ReceiptVoucherTabView from 'module/Common/ViewPages/ReceiptVoucher/ReceiptVoucherTabView';
import InventoryAdjustmentTabView from 'module/Common/ViewPages/InventoryAdjustment/InventoryAdjustmentTabView';
import SupplierPaymentTabView from 'module/Common/ViewPages/SupplierPayment/SupplierPaymentTabView';
import CreditNoteTabView from 'module/Common/ViewPages/CreditNote/CreditNoteTabView';
import BankTransferTabView from 'module/Common/ViewPages/BankTransfer/BankTransferTabView';
import DebitNoteTabView from 'module/Common/ViewPages/DebitNote/DebitNoteTabView';

const VoucherDetails = ({
  itemId,
  infoPage,
  setShowVoucherDetailArea,
  offCanvas,
  voidTransactionReport,
  type,
  getData
}) => {
  const [voucher, setVoucher] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiRequestAbortControllerRef = useRef(null);
  const fetchData = async () => {
    apiRequestAbortControllerRef?.current?.abort();
    apiRequestAbortControllerRef.current = new AbortController();
    let url = voidTransactionReport
      ? 'settings/void-transaction-details'
      : `finance/vouchers/${itemId}`;
    await axios
      .get(url, {
        params: {
          trans_type: voidTransactionReport ? type : '',
          trans_no: voidTransactionReport ? itemId : ''
        },
        signal: apiRequestAbortControllerRef.current?.signal
      })
      .then(res => {
        if (res?.data?.success) {
          if (
            res?.data?.data?.trans_type === 'PI' ||
            res?.data?.data?.trans_type === 'SD' ||
            res?.data?.data?.trans_type === 'PD' ||
            res?.data?.data?.trans_type === 'SI' ||
            res?.data?.data?.trans_type === 'CN' ||
            res?.data?.data?.trans_type === 'DN'
          ) {
            setVoucher(res?.data?.data?.trans_object);
            setLoading(false);
          } else if (
            res?.data?.data?.trans_type === 'CR' ||
            res?.data?.data?.trans_type === 'PV' ||
            res?.data?.data?.trans_type === 'JV' ||
            res?.data?.data?.trans_type === 'RV' ||
            res?.data?.data?.trans_type === 'IA' ||
            res?.data?.data?.trans_type === 'SP' ||
            res?.data?.data?.trans_type === 'BT'
          ) {
            setVoucher(res?.data?.data);
            setLoading(false);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    setVoucher([]);
    if (itemId) {
      fetchData();
    }
  }, [itemId]);

  return (
    <>
      {loading && !offCanvas ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : loading && offCanvas ? (
        <div
          className={'d-flex h-100 align-items-center justify-content-center'}
        >
          <LoadingScreen message="Looking for details" />
        </div>
      ) : voucher.trans_type === 'SI' ? (
        <SalesInvoiceTabView
          infoPage={infoPage}
          offCanvas={offCanvas}
          salesInvoiceData={voucher}
          loading={loading}
          fetchData={fetchData}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
        />
      ) : voucher.trans_type === 'SD' ? (
        <SalesDeliveryTabView
          infoPage={infoPage}
          salesDeliveryData={voucher}
          loading={loading}
          offCanvas={offCanvas}
          fetchData={fetchData}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
        />
      ) : voucher.trans_type === 'PI' ? (
        <PurchaseInvoiceTabView
          infoPage={infoPage}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          purchaseInvoiceData={voucher}
          loading={loading}
          fetchData={fetchData}
          voidTransactionReport={voidTransactionReport}
        />
      ) : voucher.trans_type === 'PD' ? (
        <PurchaseDeliveryTabView
          purchaseDeliveryData={voucher}
          loading={loading}
          fetchData={fetchData}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          offCanvas={offCanvas}
        />
      ) : voucher.trans_type === 'CR' ? (
        <CustomerReceiptTabView
          customerReceiptData={voucher}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
        />
      ) : voucher.trans_type === 'PV' ? (
        <PaymentVoucherTabView
          paymentVoucherData={voucher}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      ) : voucher.trans_type === 'JV' ? (
        <JournalVoucherTabView
          journalVoucherData={voucher}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      ) : voucher.trans_type === 'RV' ? (
        <ReceiptVoucherTabView
          receiptVoucherData={voucher}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      ) : voucher.trans_type === 'BT' ? (
        <BankTransferTabView
          BankTransferVoucherData={voucher}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
        />
      ) : voucher.trans_type === 'IA' ? (
        <InventoryAdjustmentTabView
          inventoryAdjustmentVoucherData={voucher}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      ) : voucher.trans_type === 'SP' ? (
        <SupplierPaymentTabView
          supplierPaymentVoucherData={voucher}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
        />
      ) : voucher.trans_type === 'CN' ? (
        <CreditNoteTabView
          creditNoteVoucherData={voucher}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
        />
      ) : voucher.trans_type === 'DN' ? (
        <DebitNoteTabView
          debitNoteInfoData={voucher}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
        />
      ) : (
        ''
      )}
    </>
  );
};

VoucherDetails.propTypes = {
  itemId: PropTypes.string,
  handleDelete: PropTypes.func,
  infoPage: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  offCanvas: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  type: PropTypes.string,
  getData: PropTypes.func
};

export default VoucherDetails;
