import axios from 'axios';
import { salesEndPoints } from 'module/Common/Api/endpoints';

export async function fetchCustomerDetails(
  customerId,
  setFormData,
  valueObject,
  formData,
  dataToBePopulate,
  setSalesType
) {
  await axios
    .get(`${salesEndPoints.customer}/${customerId}`)
    .then(res => {
      if (res?.data?.success) {
        if (res?.data?.data?.sales_type_id && dataToBePopulate?.sales_type) {
          setSalesType([res?.data?.data?.sales_type_id]);
        }
        setFormData(prev => ({
          ...prev,
          customer_id: customerId ?? '',
          customer_id_ref: valueObject ?? '',
          commission_enabled: dataToBePopulate?.commission_enabled
            ? res?.data?.data?.commission_enabled
            : 0,
          payment_term_id: dataToBePopulate?.payment_term
            ? res?.data?.data?.payment_term_id ??
              formData?.payment_term_id ??
              ''
            : prev?.payment_term_id,
          payment_term_id_ref: dataToBePopulate?.payment_term
            ? res?.data?.data?.payment_term_id
              ? {
                  label: res?.data?.data?.payment_term_name ?? '',
                  value: res?.data?.data?.payment_term_id ?? ''
                }
              : formData?.payment_term_id
              ? formData?.payment_term_id_ref
              : ''
            : prev?.payment_term_id_ref,
          sales_type_id: dataToBePopulate?.sales_type
            ? res?.data?.data?.sales_type_id
              ? res?.data?.data?.sales_type_id
              : formData?.sales_type_id
              ? formData?.sales_type_id
              : ''
            : prev?.sales_type_id,
          sales_type_id_ref: dataToBePopulate?.sales_type
            ? res?.data?.data?.sales_type_id
              ? {
                  label: res?.data?.data?.sales_type_name ?? '',
                  value: res?.data?.data?.sales_type_id ?? ''
                }
              : formData?.sales_type_id_ref
              ? formData?.sales_type_id_ref
              : ''
            : prev?.sales_type_id_ref,
          salesman_id: dataToBePopulate?.salesman
            ? res?.data?.data?.salesman_id ?? ''
            : prev?.salesman_id,
          salesman_id_ref: dataToBePopulate?.salesman
            ? res?.data?.data?.salesman_id
              ? {
                  label: res?.data?.data?.salesman_name,
                  value: res?.data?.data?.salesman_id
                }
              : ''
            : prev?.salesman_id_ref,
          email: dataToBePopulate?.email
            ? res?.data?.data?.email ?? ''
            : prev?.email,
          mobile: dataToBePopulate?.mobile
            ? res?.data?.data?.phone ?? ''
            : prev?.mobile,
          delivery_address: dataToBePopulate?.address
            ? res?.data?.data?.address ?? ''
            : prev?.delivery_address,
          details: dataToBePopulate?.details ? [] : prev?.details
        }));
        return true;
      } else {
        return false;
      }
    })
    .catch(error => {
      if (error) {
        return false;
      }
    });
}
