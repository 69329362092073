function checkKeyExistInProps(jsonString, key) {
  try {
    const parsedObject = JSON.parse(jsonString);
    return parsedObject[key] === true;
  } catch (error) {
    console.error('Invalid JSON provided', error);
    return false;
  }
}

export default checkKeyExistInProps;
