import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function AccountCurrencyDetailsItem({
  title,
  value,
  badgeBg,
  titleSeparator
}) {
  const Translate = useAxisproTranslate();
  return (
    <div className="d-flex flex-row align-items-center gap-2">
      <span>{`${Translate(title)} ${titleSeparator || ':'}`}</span>
      <Badge className="rounded" bg={badgeBg || 'primary'}>
        {value}
      </Badge>
    </div>
  );
}

AccountCurrencyDetailsItem.propTypes = {
  title: PropTypes.string.isRequired,
  titleSeparator: PropTypes.oneOf([':', '=']),
  value: PropTypes.string.isRequired,
  badgeBg: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning ',
    'info',
    'light',
    'dark'
  ])
};
