import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { showToast } from 'module/Common/Toast/toast';
import { StoreContext } from 'context/Context';
import { autoFocusInput } from 'module/Sales/common/functions/autoFocusInput';
import { commonEndPoints } from 'module/Common/Api/endpoints';
import { calculateTotalVisibleColumns } from 'module/Common/Functions/CalculateTotalVisibleColumns';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SalesQuotationTableForm from './SalesQuotationTableForm';
import SalesQuotationItem from './SalesQuotationItem';
import TablePlaceholder from 'module/Common/TablePlaceholder';
import SalesTableHead from 'module/Sales/common/SalesTableHead';
import handleTableItem from 'module/Purchase/common/functions/handleTableItem';
import setNewFormData from 'helpers/setNewFormData';
import LineItemLimitWarning from 'module/Common/Warnings/LineItemLimitWarning';
import useFormSettingsHook from 'hooks/useFormSettingsHook';

const SalesQuotationTable = ({
  entries,
  formData,
  setFormData,
  formError,
  setSubTotal,
  setNetDiscount,
  setTax,
  setDraft,
  salesQuotationId,
  draft,
  resumeDraftData,
  salesType,
  // setFormError,
  kitItems,
  setKitItems,
  kitFullDetails,
  setKitFullDetails,
  transDate,
  setUpdateEnable,
  setDataProcessing,
  taxType,
  convertType,
  isFromPms,
  customerCurrencyDetails
}) => {
  const Translate = useAxisproTranslate();
  const { store } = useContext(StoreContext);
  const [amount, setAmount] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState([]);
  const [itemTax, setItemTax] = useState([]);
  const [discountMethod, setDiscountMethod] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [onUpdate, setOnUpdate] = useState(false);
  const [editDisable, setEditDisabled] = useState(false);
  const [items, setItems] = useState([]);
  const [lineItemLimitWarning, setLineItemLimitWarning] = useState(false);
  const [kitDetails, setKitDetails] = useState([]);
  const [kitDetailsForPopup, setKitDetailForPopup] = useState([]);
  const { settings: SQ_settings } = useFormSettingsHook('SQ');
  const SQItemTableHeaders = {
    discount: true,
    item_code: true,
    unit: true,
    unit_tax: true
  };
  const totalVisibleColumns = calculateTotalVisibleColumns(
    10,
    SQItemTableHeaders,
    SQ_settings
  );

  const [itemIsAdding, setItemIsAdding] = useState(false);
  const onTableItemChange = async (data, method, saveToDraft) => {
    setItemIsAdding(true);
    const updatedKitItems = kitItems.map(kitItem => {
      const matchingDataItem =
        data.kit_items &&
        data.kit_items.length > 0 &&
        data.kit_items.find(dataItem => dataItem.stock_id === kitItem.stock_id);
      if (matchingDataItem) {
        return { ...kitItem, quantity: matchingDataItem.base_quantity_ref };
      }
      return kitItem;
    });
    handleTableItem({
      data: data,
      method: method,
      setFormData: setFormData,
      saveToDraft: saveToDraft ?? false
    });

    if (updatedKitItems && updatedKitItems.length > 0) {
      setKitItems(updatedKitItems);
    }

    setItemIsAdding(false);
  };
  //To calculate line item line total, discount and tax when new sales quotation or editing sales quotation
  useEffect(() => {
    let total_amount = entries.map(item => parseFloat(item.line_total));
    let total_discount = entries.map(
      item => item.discount_amount * item.quantity
    );
    let total_tax = entries.map(item => item.unit_tax * item.quantity);
    setAmount(total_amount);
    setTotalDiscount(total_discount);
    setItemTax(total_tax);
  }, [entries.length, draft]);

  //Calculate in the case of resuming draft data
  useEffect(() => {
    let total_amount = entries.map(item => parseFloat(item.line_total));
    let total_discount = entries.map(
      item => item.discount_amount * item.quantity
    );
    let total_tax = entries.map(item => item.unit_tax * item.quantity);
    setAmount(total_amount);
    setTotalDiscount(total_discount);
    setItemTax(total_tax);
  }, [resumeDraftData && entries.length]);

  //Reset all of them when delete all the line item
  useEffect(() => {
    if (entries.length === 0) {
      setAmount('');
      setTotalDiscount('');
      setItemTax('');
      setSubTotal('');
      setNetDiscount('');
      setTax('');
    }
  }, [entries.length]);

  //Calculating the sum of line total
  useEffect(() => {
    if (amount.length > 0) {
      let total = amount.reduce((a, b) => a + b);
      setSubTotal(total);
    }
  }, [amount, amount.length]);

  //Calculating sum of discount
  useEffect(() => {
    if (totalDiscount.length > 0) {
      let disc = totalDiscount.reduce((a, b) => a + b);
      setNetDiscount(disc);
    }
  }, [totalDiscount, totalDiscount.length]);

  //Calculating sum of tax
  useEffect(() => {
    if (itemTax.length > 0) {
      let totalTax = itemTax.reduce((a, b) => a + b);
      setTax(totalTax);
    }
  }, [itemTax, itemTax.length]);

  useEffect(() => {
    if (
      formData.trans_date != store?.currentDate &&
      formData.trans_date != transDate
    ) {
      if (formData.trans_date && entries.length > 0) {
        entries.map((item, index) => {
          const addSalesType = { sales_type_id: salesType[0] };
          const addTransDate = { trans_date: formData.trans_date };
          let editData = Object.assign(item, addSalesType, addTransDate);
          handleUpdateTableData(editData, index);
        });
      }
    }
  }, [formData.trans_date]);

  //Add new line item at that time called add-to-cart api to get the line total depends on tax
  const handleAddTableData = data => {
    if (formData?.details?.length <= 59) {
      if (discountMethod) {
        if (data && data.discount_amount && data.discount_percent) {
          delete data.discount_amount;
        } else if (data && data.discount_amount) {
          data = { ...data, discount_percent: data.discount_amount };
          delete data.discount_amount;
        }
      } else if (!discountMethod) {
        if (data && data.discount_amount && data.discount_percent) {
          delete data.discount_percent;
        } else if (data && data.discount_percent) {
          data = { ...data, discount_amount: data.discount_percent };
          delete data.discount_percent;
        }
      }
      setOnSave(true);
      let arrayOfCartItems = setNewFormData({
        items: [{ id_ref: '1', ...data }],
        trans_type: 'SQ'
      });
      axios
        .post(commonEndPoints.addToCartBulk, arrayOfCartItems)
        .then(response => {
          if (response.data.data.items[0].is_kit == 1) {
            setKitDetails([response.data.data.items[0]]);
            setKitDetailForPopup(prev => [
              ...prev,
              response.data.data.items[0]
            ]);
          }
          setFormData(prev => ({
            ...prev,
            details: [...prev.details, response.data.data.items[0]]
          }));
          setDraft(!draft);
          setOnSave(false);
          autoFocusInput('stock_id');
        })
        .catch(error => {
          setOnSave(false);
          if (error?.response?.data?.message) {
            const validation_error =
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.errors
                ? error.response.data.data.errors
                : null;
            console.log(validation_error);
          } else {
            showToast(
              Translate(
                'Something went wrong, please refresh the page and try again'
              ),
              'error'
            );
          }
        });
    } else {
      setLineItemLimitWarning(true);
    }
  };

  useEffect(() => {
    if (items.length > 0 && kitFullDetails.length > 0) {
      const kitArray = kitFullDetails.filter(
        obj => obj.kit_id === items[0].kit_id
      );
      const newArray = kitArray[0].kit_items.map(item => {
        return {
          kit_id: item.kit_id,
          stock_id: item.stock_id,
          quantity: item.initial_quantity
            ? item.initial_quantity * kitDetails[0].quantity
            : item.quantity * kitDetails[0].quantity,
          kit_quantity: kitDetails[0].quantity,
          unit_price: item.price ? item.price : item.unit_price,
          is_kit: kitDetails[0].is_kit,
          item_display_name:
            item && item.item && item.item.item_name
              ? item.item.item_name
              : item.item_display_name,
          discount_amount: kitDetails[0].discount_amount,
          discount_percent: kitDetails[0].discount_percent,
          sales_type_id: kitDetails[0].sales_type_id,
          unit_tax: kitDetails[0].unit_tax,
          line_total: kitDetails[0].line_total,
          line_total_formatted: kitDetails[0].line_total_formatted,
          line_total_discount: kitDetails[0].line_total_discount,
          linet_total_tax: kitDetails[0].line_total_tax,
          net_discount_amount: kitDetails[0].net_discount_amount,
          sub_total: kitDetails[0].sub_total,
          tax_free_unit_price: kitDetails[0].tax_free_unit_price,
          unit_name: kitDetails[0].unit_name,
          id: salesQuotationId ? (item && item.item ? null : item.id) : item.id,
          order_detail_id: item.order_detail_id ? item.order_detail_id : null,
          sales_invoice_id: item.sales_invoice_id
            ? item.sales_invoice_id
            : null,
          item_description: kitDetails[0].item_description
        };
      });
      setKitItems([...kitItems, ...newArray]);
      setKitDetails([]);
      setItems([]);
    }
  }, [kitDetails.length && items.length]);

  //Updating the already added line item
  const handleUpdateTableData = (data, index) => {
    setOnUpdate(true);
    if (discountMethod) {
      delete data.discount_amount;
    } else {
      null;
    }
    const prevData = entries[index];
    if (prevData) {
      entries[index] = data;
      data.edit = false;
      data.kit_items = [];
      if (!data?.sales_type_id) {
        data.sales_type_id = formData?.sales_type_id;
      }
      const arrayOfCartItems = {
        items: [
          {
            id_ref: index,
            ...data
          }
        ],
        trans_type: 'SQ'
      };
      axios
        .post(commonEndPoints.addToCartBulk, arrayOfCartItems)
        .then(response => {
          if (response.data.data.items[0].is_kit == 1) {
            setKitDetails([response.data.data.items[0]]);
            setKitDetailForPopup(prev => [
              ...prev,
              response.data.data.items[0]
            ]);
            let filteredItems = kitItems.filter(
              item => item.kit_id !== response.data.data.items[0].kit_id
            );
            setKitItems([...filteredItems]);
            let newItems = kitItems.filter(
              item => item.kit_id === response.data.data.items[0].kit_id
            );
            setItems(newItems);
          }
          setFormData(prev => ({
            ...prev,
            details: [
              ...prev.details.slice(0, index),
              response.data.data.items[0],
              ...prev.details.slice(index + 1)
            ]
          }));
          setDraft(!draft);
          setEditDisabled(false);
          setOnUpdate(false);
          autoFocusInput('stock_id');
        })
        .catch(error => {
          setEditDisabled(false);
          setOnUpdate(false);
          if (error?.response?.data?.message) {
            const validation_error =
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.errors
                ? error.response.data.data.errors
                : null;
            // This will be converted to table line errors in fututre - Need to implement add-to-cart-bulk in so,sd and si then will implement this also
            console.log(validation_error);
          } else {
            showToast(
              Translate(
                'Something went wrong, please refresh the page and try again'
              ),
              'error'
            );
          }
        });
    }
  };

  //Delete the line item
  const handleRemoveTableItem = index => {
    setDraft(!draft);
    const removingItem = entries[index];
    if (removingItem) {
      if (removingItem.is_kit == 1) {
        let removingKitItems = kitItems.filter(
          item => item.kit_id !== removingItem.kit_id
        );
        setKitItems([...removingKitItems]);
        let removeKitFullDetails = kitFullDetails.filter(
          item => item.kit_id !== removingItem.stock_id
        );
        setKitFullDetails([...removeKitFullDetails]);
        setKitDetailForPopup([...removeKitFullDetails]);
      }

      entries.splice(index, 1);
      setFormData(prev => ({
        ...prev,
        details: [...entries]
      }));
      amount.splice(index, 1);
      totalDiscount.splice(index, 1);
      itemTax.splice(index, 1);
    }
  };

  const handleEditTableData = index => {
    setEditDisabled(true);
    const addSalesType = { sales_type_id: salesType[0] };
    let editableItem = Object.assign(entries[index], addSalesType);
    if (editableItem) {
      entries[index].edit = true;
      //The commented code changed due to cannot edit line item multiple times - If any issues occur with this code will change it - Now under testing
      // entries[index].edit = !editableItem.edit;
      setFormData(prev => ({ ...prev, details: entries }));
    }
  };

  return (
    <>
      <Table
        striped
        bordered
        responsive
        className="form-table-layout mb-7 simple-table-animation"
      >
        <SalesTableHead
          taxType={taxType}
          convertType={convertType}
          transType="SQ"
          customerCurrencyDetails={customerCurrencyDetails}
        />
        <tbody>
          {entries.length > 0 ? (
            <>
              {onSave ? (
                <>
                  {entries.map((entry, index) =>
                    (entry.edit === 'false' ? false : entry.edit) ? (
                      <SalesQuotationTableForm
                        key={index}
                        editItemData={entry}
                        index={index}
                        setDiscountMethod={setDiscountMethod}
                        discountMethod={discountMethod}
                        handleSubmit={handleUpdateTableData}
                        onCancel={() => {
                          handleEditTableData(index);
                          setEditDisabled(false);
                        }}
                        onUpdate={onUpdate}
                        salesType={salesType}
                        setItems={setItems}
                        kitItems={kitItems}
                        setKitFullDetails={setKitFullDetails}
                        formData={formData}
                        setDataProcessing={setDataProcessing}
                        convertType={convertType}
                        isFromPms={isFromPms}
                      />
                    ) : (
                      <SalesQuotationItem
                        entry={entry}
                        key={index}
                        index={index}
                        error={formError}
                        discountMethod={discountMethod}
                        handleEditTableData={handleEditTableData}
                        handleRemoveTableItem={handleRemoveTableItem}
                        editDisable={editDisable}
                        kitFullDetails={
                          kitDetailsForPopup.length > 0
                            ? kitDetailsForPopup[index]
                            : kitFullDetails[index]
                        }
                        itemIsAdding={itemIsAdding}
                        onTableItemChange={onTableItemChange}
                        formData={formData}
                        kitItems={kitItems}
                        setKitItems={setKitItems}
                        setUpdateEnable={setUpdateEnable}
                        convertType={convertType}
                        customerCurrencyDetails={customerCurrencyDetails}
                      />
                    )
                  )}
                </>
              ) : (
                entries.map((entry, index) =>
                  (entry.edit === 'false' ? false : entry.edit) ? (
                    onUpdate ? (
                      <TablePlaceholder row={1} column={totalVisibleColumns} />
                    ) : (
                      <SalesQuotationTableForm
                        key={index}
                        editItemData={entry}
                        index={index}
                        setDiscountMethod={setDiscountMethod}
                        discountMethod={discountMethod}
                        handleSubmit={handleUpdateTableData}
                        onCancel={() => {
                          handleEditTableData(index);
                          setEditDisabled(false);
                        }}
                        onUpdate={onUpdate}
                        salesType={salesType}
                        setItems={setItems}
                        kitItems={kitItems}
                        setKitFullDetails={setKitFullDetails}
                        formData={formData}
                        setDataProcessing={setDataProcessing}
                        convertType={convertType}
                        isFromPms={isFromPms}
                      />
                    )
                  ) : (
                    <SalesQuotationItem
                      entry={entry}
                      key={index}
                      index={index}
                      error={formError}
                      discountMethod={discountMethod}
                      handleEditTableData={handleEditTableData}
                      handleRemoveTableItem={handleRemoveTableItem}
                      editDisable={editDisable}
                      kitFullDetails={
                        kitDetailsForPopup.length > 0
                          ? kitDetailsForPopup[index]
                          : kitFullDetails[index]
                      }
                      itemIsAdding={itemIsAdding}
                      onTableItemChange={onTableItemChange}
                      formData={formData}
                      kitItems={kitItems}
                      setKitItems={setKitItems}
                      setUpdateEnable={setUpdateEnable}
                      convertType={convertType}
                      customerCurrencyDetails={customerCurrencyDetails}
                    />
                  )
                )
              )}
              {entries.filter(item =>
                item.edit === 'false' ? false : item.edit
              ).length > 0 ? (
                ''
              ) : onSave ? (
                <TablePlaceholder row={1} column={totalVisibleColumns} />
              ) : (
                <SalesQuotationTableForm
                  handleSubmit={handleAddTableData}
                  setFormData={setFormData}
                  setAmount={setAmount}
                  setTotalDiscount={setTotalDiscount}
                  setItemTax={setItemTax}
                  setDiscountMethod={setDiscountMethod}
                  discountMethod={discountMethod}
                  onUpdate={onUpdate}
                  salesType={salesType}
                  setItems={setItems}
                  kitItems={kitItems}
                  setKitFullDetails={setKitFullDetails}
                  formData={formData}
                  setDataProcessing={setDataProcessing}
                  convertType={convertType}
                  isFromPms={isFromPms}
                />
              )}
            </>
          ) : editDisable && entries.length != 0 ? (
            ''
          ) : (
            <>
              {onSave ? (
                <TablePlaceholder row={1} column={totalVisibleColumns} />
              ) : (
                <tr>
                  <td colSpan={12} className="p-0">
                    <Alert
                      className="text-center m-0 mt-2 mb-2 p-1 fs--1"
                      variant="warning"
                    >
                      {Translate('Entries are required')}!
                    </Alert>
                  </td>
                </tr>
              )}
              <SalesQuotationTableForm
                handleSubmit={handleAddTableData}
                setFormData={setFormData}
                setAmount={setAmount}
                setTotalDiscount={setTotalDiscount}
                setItemTax={setItemTax}
                setDiscountMethod={setDiscountMethod}
                discountMethod={discountMethod}
                onUpdate={onUpdate}
                salesType={salesType}
                setItems={setItems}
                kitItems={kitItems}
                setKitFullDetails={setKitFullDetails}
                formData={formData}
                setDataProcessing={setDataProcessing}
                convertType={convertType}
                isFromPms={isFromPms}
              />
            </>
          )}
        </tbody>
      </Table>
      <LineItemLimitWarning
        show={lineItemLimitWarning}
        onHide={() => {
          setLineItemLimitWarning(false);
        }}
        content="You have reached the maximum limit of 60 items for this quotation. Please create a new quotation to add more items."
      />
    </>
  );
};

SalesQuotationTable.propTypes = {
  entries: PropTypes.array,
  formError: PropTypes.object,
  setFormData: PropTypes.func,
  setSubTotal: PropTypes.func,
  type: PropTypes.number,
  setNetDiscount: PropTypes.func,
  setTax: PropTypes.func,
  salesQuotationId: PropTypes.string,
  editItemId: PropTypes.any,
  setDraft: PropTypes.func,
  draft: PropTypes.bool,
  resumeDraftData: PropTypes.bool,
  salesType: PropTypes.array,
  // setFormError: PropTypes.func,
  kitItems: PropTypes.any,
  setKitItems: PropTypes.func,
  kitFullDetails: PropTypes.any,
  setKitFullDetails: PropTypes.func,
  formData: PropTypes.any,
  transDate: PropTypes.any,
  setUpdateEnable: PropTypes.bool,
  setDataProcessing: PropTypes.func,
  taxType: PropTypes.bool,
  isFromPms: PropTypes.bool,
  convertType: PropTypes.string,
  customerCurrencyDetails: PropTypes.object
};

export default SalesQuotationTable;
