import { React, useLayoutEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { ButtonGroup, Card, Container, Tab, Tabs } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { AiOutlineFileText } from 'react-icons/ai';

import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { commonEndPoints } from '../../Api/endpoints';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import TransactionsData from 'module/Common/Timeline/TransactionsData';
import SalesQuotationView from './SalesQuotationView';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import PDFView from 'components/PDFViewerButton/PDFView';
import EstimationReferenceView from 'components/EstimationReferenceView/EstimationReferenceView';
import RevisionTable from 'module/Common/ViewPages/Common/Tables/RevisionTable';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import ApproveButton from 'components/approval-component/button/ApproveButton';
import RejectButton from 'components/approval-component/button/RejectButton';
import ActivityLog from 'components/activity-log/ActivityLog';
import checkKeyExistInProps from 'helpers/checkKeyExistInProps';
function SalesQuotationTabView({
  salesQuotationData,
  loading,
  voidScreen,
  infoPage,
  onHide,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  let navigateTo = useNavigate();
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [queryParams] = useSearchParams();
  const [key, setKey] = useState(voidScreen && 'voidForm');
  const [transactions, setTransactions] = useState([]);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const status = queryParams.get('status');
  const permission = {
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    print_sq: getAcodaxPermissionSlug('SQ', 'print'),
    update_sq: getAcodaxPermissionSlug('SQ', 'update'),
    revise_sq: getAcodaxPermissionSlug('SQ', 'revise'),
    edit_sq: getAcodaxPermissionSlug('CSQ', 'update_created'),
    create_so: getAcodaxPermissionSlug('SO', 'create'),
    send_mail: getAcodaxPermissionSlug('SQ', 'send-mail'),
    void_sq: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };
  const value = {
    value: salesQuotationData.customer_id
  };

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  useLayoutEffect(() => {
    if (salesQuotationData && salesQuotationData?.connected_orders) {
      let connected = [...salesQuotationData.connected_orders];
      let sorted = connected.sort((a, b) =>
        a.timestamp.localeCompare(b.timestamp)
      );
      setTransactions(sorted);
    }
  }, [salesQuotationData]);
  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <AiOutlineFileText size={28} className="me-2 text-dark" />
              <h5
                className="mb-0 d-flex text-uppercase view-page-header"
                style={{ fontSize: '25px' }}
              >
                {Translate('Sales Quotation')}
              </h5>
            </div>
            {voidTransactionReport && (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            )}
            {salesQuotationData?.props === 'is_from_pms' && (
              <Badges
                label={'Is from PMS'}
                className="ms-2 fs--1"
                bg={'warning'}
              />
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center dropdown-menu-style">
              {(status && status !== 'approved-request') ||
              voidScreen ||
              offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  emailPermission={permission?.send_mail}
                  firstConversionValidation={
                    permission?.create_so &&
                    !checkKeyExistInProps(
                      salesQuotationData?.props,
                      'is_from_pms'
                    )
                  }
                  disableFirstConversion={false}
                  firstConversionTo={
                    '/sales/sales-order/add/' + salesQuotationData.id
                  }
                  afterFirstConversion={'Ordered!'}
                  beforeFirstConversion={'Convert to Sales order'}
                  revise={{
                    path: `/sales/sales-quotation/${
                      salesQuotationData.id
                    }?${createSearchParams({
                      ...allQueryParams,
                      revise: true
                    })}`,
                    permission: permission?.revise_sq
                  }}
                  voidTransactionData={
                    permission?.void_sq
                      ? {
                          id: salesQuotationData?.id,
                          trans_type: 'SQ',
                          callBack: () => {
                            navigateTo(
                              `/sales/sales-quotation/info?${createSearchParams(
                                {
                                  ...allQueryParams
                                }
                              )}`
                            );
                            typeof getData === 'function' && getData(1);
                          }
                        }
                      : undefined
                  }
                  proposalData={{
                    data: salesQuotationData,
                    sqId: salesQuotationData?.id
                  }}
                />
              )}
              {(status && status !== 'approved-request') ||
              infoPage ||
              voidScreen ||
              offCanvas ? (
                ''
              ) : permission?.update_sq || permission?.edit_sq ? (
                <EditButton
                  to={`/sales/sales-quotation/edit/${
                    salesQuotationData.id
                  }?${createSearchParams({
                    ...allQueryParams
                  })}`}
                  disable={salesQuotationData?.revision > 0}
                  message={
                    'Sales Quotation is revised so cant be edit this sales quotation'
                  }
                />
              ) : (
                ''
              )}
              {(status && status !== 'approved-request') ||
                (!voidTransactionReport && permission?.print_sq && (
                  <ViewPagePrintButton
                    variant="danger"
                    className="text-white"
                    style={{ boxShadow: 'none' }}
                    url={`${commonEndPoints.print.sq}/${salesQuotationData.id}`}
                    pageTitle="Sales Quotation print view"
                  />
                ))}
              {infoPage || offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                  onHide={onHide}
                />
              ) : (
                ''
              )}
              {salesQuotationData?.user_actions?.Approve &&
                status &&
                status !== 'approved-request' && (
                  <ApproveButton
                    name={salesQuotationData?.user_actions?.Approve}
                    Id={salesQuotationData?.approval_event_id}
                    data={salesQuotationData}
                    getData={getData}
                    info={true}
                  />
                )}
              {salesQuotationData?.user_actions?.Reject &&
                status &&
                status !== 'approved-request' && (
                  <RejectButton
                    name={salesQuotationData?.user_actions?.Reject}
                    Id={salesQuotationData?.approval_event_id}
                    data={salesQuotationData}
                    getData={getData}
                    info={true}
                  />
                )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex align-items-start justify-content-between">
            <div>
              {salesQuotationData?.customer_name && (
                <PersonName
                  label={'Customer Name'}
                  value={salesQuotationData?.customer_name}
                  view
                  permission={permission?.show_cs}
                  setCustomerDetailsArea={setCustomerDetailsArea}
                />
              )}
              {salesQuotationData?.trans_date_formatted && (
                <MainDetails
                  label={'Transaction Date'}
                  value={salesQuotationData?.trans_date_formatted}
                />
              )}
            </div>
            <div className="view-page-contents d-flex flex-column">
              {salesQuotationData?.reference && (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/sales/sales-quotation/info/${salesQuotationData.id}`}
                  value={salesQuotationData?.reference}
                />
              )}
              {salesQuotationData?.estimation_id ? (
                <div className="d-flex align-items-center gap-1">
                  <h6 className="mb-2 fw-bold">
                    {Translate('Estimation Reference')}:&nbsp;
                  </h6>
                  <EstimationReferenceView
                    targetId={salesQuotationData.estimation_id}
                    reference={salesQuotationData?.estimation?.reference}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
              defaultActiveKey={'overview'}
              className="custom-offcanvas-header-tabs"
            >
              {voidScreen && (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={salesQuotationData.trans_type}
                      transId={salesQuotationData.id}
                    />
                  </SimpleBar>
                </Tab>
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <SalesQuotationView salesQuotationData={salesQuotationData} />
                </SimpleBar>
              </Tab>
              {salesQuotationData?.revisions?.length > 0 && (
                <Tab eventKey="revision" title={Translate('Revisions')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <RevisionTable
                      transaction={salesQuotationData?.revisions}
                    />
                  </SimpleBar>
                </Tab>
              )}
              {transactions && transactions.length > 0 && (
                <Tab eventKey="transaction" title={Translate('Transactions')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <TransactionsData transaction={transactions} />
                  </SimpleBar>
                </Tab>
              )}
              {salesQuotationData?.media?.length > 0 && (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={salesQuotationData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              )}
              {salesQuotationData?.id && (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`${commonEndPoints.print.sq}/${salesQuotationData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              )}
              {salesQuotationData?.id && (
                <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'activityLog' && (
                      <ActivityLog subjectID={salesQuotationData?.id} />
                    )}
                  </SimpleBar>
                </Tab>
              )}
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={salesQuotationData?.created_at}
          created_by={salesQuotationData?.created_user_full_name}
        />
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={value}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      {salesQuotationData.id && (
        <FileUpload
          show={upload}
          onHide={() => setUpload(false)}
          fetchData={fetchData}
          type={'SQ'}
          id={salesQuotationData.id}
          setKey={setKey}
        />
      )}
      <SendEmail
        url={commonEndPoints.email.sq + salesQuotationData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={salesQuotationData.customer_email}
      />
    </>
  );
}

SalesQuotationTabView.propTypes = {
  salesQuotationData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  infoPage: PropTypes.bool,
  onHide: PropTypes.func,
  fetchData: PropTypes.func,
  setShowVoucherDetailArea: PropTypes.func,
  offCanvas: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default SalesQuotationTabView;
