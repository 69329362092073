import React from 'react';
import { Link } from 'react-router-dom';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaEye } from 'react-icons/fa';
import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';
import { RiFileTransferLine } from 'react-icons/ri';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

export const ViewButton = ({ to }) => {
  const Translate = useAxisproTranslate();

  return (
    <Button
      variant="transparent"
      className="p-0 m-0 ps-2 pe-2  text-primary"
      style={{ boxShadow: 'none' }}
      as={Link}
      to={to}
      title={Translate('View')}
    >
      <FaEye size={13} />
    </Button>
  );
};

ViewButton.propTypes = {
  to: PropTypes.string
};

export const EditButton = ({ to, disable, title }) => {
  const Translate = useAxisproTranslate();

  return (
    <Button
      variant="transparent"
      className=" p-0 m-0 ps-2 pe-2 text-info"
      style={{
        boxShadow: 'none',
        cursor: disable ? 'not-allowed' : 'pointer'
      }}
      as={Link}
      to={to}
      title={
        title
          ? Translate(title)
          : disable
          ? Translate(
              'Editing this invoice is not possible because a payment has been made against it.'
            )
          : Translate('Edit')
      }
      disabled={disable}
    >
      <BsPencilSquare size={14} className="text-info " />
    </Button>
  );
};

EditButton.propTypes = {
  to: PropTypes.string,
  disable: PropTypes.bool,
  title: PropTypes.string
};

export const UpdateButton = ({ onClick, disable, id }) => {
  const Translate = useAxisproTranslate();

  return (
    <Button
      variant="transparent"
      className=" p-0 m-0 ps-2 pe-2 text-info"
      style={{
        boxShadow: 'none',
        cursor: disable ? 'not-allowed' : 'pointer'
      }}
      title={Translate('Edit')}
      disabled={disable}
      onClick={() => onClick(id)}
    >
      <BsPencilSquare size={14} className="text-info " />
    </Button>
  );
};

UpdateButton.propTypes = {
  onClick: PropTypes.func,
  disable: PropTypes.bool,
  id: PropTypes.any
};

export const ConversionButton = ({
  to,
  disable,
  toolTip1,
  toolTip2,
  iconColor
}) => {
  return (
    <OverlayTrigger
      placement={'bottom'}
      overlay={
        disable ? (
          <Tooltip>
            <span className="text-success fs--2">
              {toolTip2} <TiTick />
            </span>
          </Tooltip>
        ) : (
          <Tooltip>
            <strong className="fs--2">{toolTip1}</strong>
          </Tooltip>
        )
      }
    >
      <Button
        variant="transparent"
        className={`p-0 m-0 ps-2 pe-2 ${
          iconColor ? iconColor : 'text-success'
        }`}
        style={{
          boxShadow: 'none'
        }}
        as={Link}
        to={to}
        disabled={disable}
      >
        <RiFileTransferLine size={15} />
      </Button>
    </OverlayTrigger>
  );
};

ConversionButton.propTypes = {
  to: PropTypes.string,
  disable: PropTypes.bool,
  toolTip1: PropTypes.string,
  toolTip2: PropTypes.string,
  iconColor: PropTypes.string
};

export const DeleteButton = ({ handleDelete, id, className }) => {
  const Translate = useAxisproTranslate();

  return (
    <Button
      variant="transparent"
      className={`${className} p-0 m-0 ps-2 pe-2 text-danger`}
      style={{ boxShadow: 'none' }}
      onClick={() => handleDelete(id)}
      title={Translate('Delete')}
    >
      <BsFillTrashFill size={14} />
    </Button>
  );
};

DeleteButton.propTypes = {
  handleDelete: PropTypes.func,
  id: PropTypes.any,
  className: PropTypes.string
};

export const SaveButton = ({
  onSave,
  handleSubmit,
  id,
  saveDraft,
  disable,
  disableUpdate,
  editPermission,
  createPermission,
  revise,
  buttonTitle,
  variant
}) => {
  const Translate = useAxisproTranslate();
  return (
    <>
      {(id && editPermission) || (id && revise) ? (
        <Button
          size="sm"
          className="w-10 d-flex align-items-center justify-content-center"
          type="button"
          variant={`info`}
          disabled={onSave || saveDraft || disable || disableUpdate}
          onClick={handleSubmit}
        >
          {onSave || saveDraft ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="white"
                className="me-1"
              />
              <span className="fs--1">
                {saveDraft
                  ? Translate('Save Draft...')
                  : Translate(revise ? 'Revising...' : 'Updating...')}
              </span>
            </>
          ) : (
            <span>{Translate(revise ? 'Revise' : 'Update')}</span>
          )}
        </Button>
      ) : (
        ''
      )}
      {!id && createPermission ? (
        <Button
          size="sm"
          className={`${
            !disable ? 'form-tab-switch-control' : ''
          } w-10 d-flex align-items-center justify-content-center`}
          type="button"
          variant={variant ? variant : `success`}
          disabled={onSave || saveDraft || disable || disableUpdate}
          onClick={handleSubmit}
        >
          {onSave || saveDraft ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="white"
                className="me-1"
              />
              <span className="fs--1">
                {saveDraft
                  ? Translate('Save Draft...')
                  : Translate('Saving...')}
              </span>
            </>
          ) : (
            <span>
              {buttonTitle ? Translate(buttonTitle) : Translate('Save')}
            </span>
          )}
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

SaveButton.propTypes = {
  onSave: PropTypes.bool,
  handleSubmit: PropTypes.func,
  id: PropTypes.any,
  saveDraft: PropTypes.bool,
  disableUpdate: PropTypes.bool,
  disable: PropTypes.bool,
  editPermission: PropTypes.bool,
  createPermission: PropTypes.bool,
  revise: PropTypes.bool,
  buttonTitle: PropTypes.any,
  variant: PropTypes.string
};

export const CancelButton = ({
  onSave,
  handleCancel,
  disable,
  clear,
  variant
}) => {
  const Translate = useAxisproTranslate();

  return (
    <Button
      size="sm"
      type="button"
      variant={variant ? variant : 'danger'}
      className={`${!disable ? 'form-tab-switch-control' : ''} w-10`}
      onClick={handleCancel}
      disabled={onSave || disable}
    >
      {clear ? Translate('Clear') : Translate('Cancel')}
    </Button>
  );
};

CancelButton.propTypes = {
  onSave: PropTypes.bool,
  handleCancel: PropTypes.func,
  disable: PropTypes.bool,
  clear: PropTypes.bool,
  variant: PropTypes.string
};
