import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function CustomerLpoNumberField({
  formData,
  formError,
  handleFieldChange,
  handleInputBoxOutofFocus,
  handleInputBoxFocus,
  enableDraft
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group as={Col} xxl={4} xl={6} md={6} sm={12} className="mb-1">
      <Row>
        <Col xl={4} md={4} sm={12} className="pe-0">
          <Form.Label
            className={
              ' m-0 d-flex align-items-center justify-content-start h-100'
            }
          >
            {Translate('Customer LPO Number')}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12} className={'ps-0'}>
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
              <Form.Control
                type="text"
                name="customer_lpo_number"
                className="form-tab-switch-control"
                onChange={e => handleFieldChange(e)}
                value={formData.customer_lpo_number}
                onBlur={() => {
                  enableDraft ? handleInputBoxOutofFocus() : '';
                }}
                onFocus={() => {
                  enableDraft ? handleInputBoxFocus() : '';
                }}
              />
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              <FormErrorPopover
                id="customer_lpo_number"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError.customer_lpo_number}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

CustomerLpoNumberField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  handleInputBoxFocus: PropTypes.func,
  handleInputBoxOutofFocus: PropTypes.func,
  enableDraft: PropTypes.bool
};

export default CustomerLpoNumberField;
