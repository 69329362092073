import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaSpinner } from 'react-icons/fa';

const LoadingOverlay = ({ search }) => {
  return search ? (
    <div className="loading-overlay-for-search is-active">
      <FontAwesomeIcon icon="search" className="search-icon" />
    </div>
  ) : (
    <div className={`loading-overlay is-active`}>
      <FaSpinner size={20} className="spinner text-light" />
    </div>
  );
};

LoadingOverlay.propTypes = {
  search: PropTypes.bool
};

export default LoadingOverlay;
