import axios from 'axios';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import { showToast } from 'module/Common/Toast/toast';
import React, { useContext, useState } from 'react';
import { Alert, Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useSearchParams } from 'react-router-dom';
import { FaLock } from 'react-icons/fa';
import { AuthWizardContext } from 'context/Context';
const MultiTenancyPassword = () => {
  const { dispatch } = useContext(AuthWizardContext);
  const [validatingUser, setValidatingUser] = useState(false);
  const [formData, setFormData] = useState({ password: '' });
  const [queryParams] = useSearchParams();
  const tenant_id = queryParams.get('tenant_id');
  const [errors, setErrors] = useState({});
  const email = queryParams.get('email');
  const handleFieldChange = e =>
    setFormData(generateFormDataHelper(e, null, formData));
  const handleSubmit = e => {
    e.preventDefault();
    if (!formData.password)
      return showToast(`Invalid requestpassword is required.`, 'error');

    setValidatingUser(true);
    axios
      .post(
        'auth/login',
        { identifier: email, ...formData },
        {
          headers: {
            'X-Acodax-Tenant-Id': tenant_id
          }
        }
      )
      .then(response => {
        setValidatingUser(false);
        if (response.data.success !== true)
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );

        const userData = {
          ...response.data.data,
          username: formData.username,
          lockScreen: false
        };
        axios.interceptors.request.use(config => {
          config.headers.common['X-Acodax-Tenant-Id'] = tenant_id;
          return config;
        });
        if ('BroadcastChannel' in window) {
          const loginChannel = new BroadcastChannel('loginChannel');
          loginChannel.postMessage({
            type: 'login',
            message: 'User has logged out'
          });
          loginChannel.close();
        }
        // save user data with token to localStorage
        localStorage.setItem('AXIS_PRO_USER', JSON.stringify(userData));
        localStorage.setItem('ACODAX_MANUEL_LOGOUT', false);
        localStorage.setItem('ACODAX_X_ACODAX_TENANT_ID', tenant_id);
        // update auth context
        dispatch({ type: 'UPDATE', payload: { ...userData } });
        showToast(response.data.message, 'success');
      })
      .catch(error => {
        setValidatingUser(false);
        if (!error.response.data || !error.response.data.message)
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );

        const validation_error =
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.errors
            ? error.response.data.data.errors
            : null;

        validation_error && setErrors({ ...validation_error });
      });
  };

  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <Form.Group className="mb-4 text-center">
        <h1 className="fw-700 ">Enter Password</h1>
      </Form.Group>
      {Object.keys(errors).length > 0 ? (
        <Form.Group className="mb-3">
          <Alert variant="danger">{errors?.password}</Alert>
        </Form.Group>
      ) : null}
      <Form.Group className="mb-3" controlId="password">
        <InputGroup className={` rounded shadow py-1 `}>
          <InputGroup.Text className="border-0 bg-transparent px-2">
            <FaLock size={15} />
          </InputGroup.Text>
          <Form.Control
            type="password"
            className="border-0"
            placeholder="Enter Password"
            name="password"
            onChange={handleFieldChange}
            value={formData.password}
            isInvalid={!!errors.password}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="d-flex flex-row">
        <Button
          variant="success"
          type="submit"
          size="sm"
          className="rounded w-100  d-flex flex-row justify-content-center align-items-center"
          disabled={validatingUser}
        >
          {validatingUser ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="white"
                className="me-1"
                style={{ width: '12px', height: '12px' }}
              />
              <span className="fs--1">Validating...</span>
            </>
          ) : (
            <>
              <span>Login</span>
              <FaArrowRightLong className="ms-2 pt-1" />
            </>
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default MultiTenancyPassword;
