import { React } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';

const SortReport = ({ sortOptions }) => {
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const sortBy = queryParams.get('sort_by');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleFieldChange = e => {
    navigate(
      `${pathname}?${createSearchParams({
        ...allQueryParams,
        sort_by: e.target.value
      })}`
    );
  };

  return (
    <div className="d-flex">
      <Form.Select
        name="sortValue"
        value={sortBy || 'SORT BY'}
        aria-label="Default select example"
        style={{ width: '13rem' }}
        onChange={handleFieldChange}
      >
        <option>SORT BY</option>
        {sortOptions.map((items, key) => (
          <option key={key} value={items.value}>
            {items.label}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};
SortReport.propTypes = {
  formData: PropTypes.any,
  sortOptions: PropTypes.array,
  setFormData: PropTypes.bool
};
export default SortReport;
