import { React, useState } from 'react';
import axios from 'axios';
import { Alert, Button, Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Draft from '../../../assets/gif/draft.png';

function DraftData({
  show,
  onHide,
  savedDraftData,
  setResumeDraftData,
  setDeleteDraft,
  setDraftId,
  setSalesType,
  sales
}) {
  const Translate = useAxisproTranslate();
  const [loading, setLoading] = useState(false);

  const handleResume = () => {
    setResumeDraftData(true);
    onHide();
  };

  const handleDiscard = async () => {
    setLoading(true);
    await axios
      .delete(`draft/discard/${savedDraftData.data.id}`)
      .then(res => {
        if (res.data.success === true) {
          setDraftId(0);
          if (sales) {
            setSalesType([]);
          }
          setDeleteDraft(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
    onHide();
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-with-overlay"
    >
      <Card>
        <Alert variant="light" className="m-0 p-3">
          <Card.Header className="p-0 text-center pt-2">
            <img
              src={Draft}
              alt=""
              style={{
                width: '6rem'
              }}
            />
          </Card.Header>
          <Card.Body className="p-0 mt-2 d-flex align-items-center justify-content-center w-100">
            <div style={{ textAlign: 'center' }}>
              <h5 className="fs--1 mt-2" style={{ maxWidth: '25rem' }}>
                {savedDraftData !== '' ? savedDraftData.message : ''}
              </h5>
            </div>
          </Card.Body>
          <Card.Footer className="mt-3 p-0 d-flex align-items-center justify-content-center gap-2">
            {loading ? (
              <>
                <Button
                  variant="danger"
                  size="sm"
                  className="p-1 pe-2 px-2 form-tab-switch-control"
                  disabled={loading}
                >
                  {Translate('Discarding...')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="danger"
                  size="sm"
                  className="p-1 pe-2 px-2 form-tab-switch-control"
                  onClick={handleDiscard}
                  autoFocus
                >
                  {Translate('Discard')}
                </Button>
                <Button
                  variant="success"
                  size="sm"
                  className="p-1 pe-2 px-2 form-tab-switch-control"
                  onClick={handleResume}
                >
                  {Translate('Open')}
                </Button>
              </>
            )}
          </Card.Footer>
        </Alert>
      </Card>
    </Modal>
  );
}

DraftData.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.any,
  setDeleteDraft: PropTypes.func,
  setResumeDraftData: PropTypes.func,
  savedDraftData: PropTypes.object,
  setDraftId: PropTypes.func,
  setSalesType: PropTypes.func,
  sales: PropTypes.bool
};

export default DraftData;
