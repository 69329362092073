const FilterOptions = {
  rental_contracts: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    date: [
      { name: 'contract_from', Heading: 'Contract From' },
      { name: 'contract_to', Heading: 'Contract To' }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        fixedAsset: true
      }
    ],
    customSelect: [
      {
        name: 'rental_status',
        Heading: 'Rental Status',
        option: [
          {
            label: 'Returned',
            value: 'returned'
          },
          {
            label: 'Pending',
            value: 'pending'
          },
          {
            label: 'Rented Out',
            value: 'rented_out'
          }
        ]
      }
    ],
    customDropDown: [{ name: 'status', Heading: 'Status' }]
  },
  material_request_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'allocation_id', Heading: 'Reference' }]
  },
  employee_allocation: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    dateAndTimePicker: [
      {
        name: 'issued_at',
        Heading: 'Issued At'
      },
      {
        name: 'returned_at',
        Heading: 'Returned At'
      }
    ],
    textBox: [{ name: 'allocation_id', Heading: 'Reference' }],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        fixedAsset: true
      }
    ],
    user: [
      {
        name: 'receiver_id',
        Heading: 'Employee'
      }
    ],
    searchEnd: true
  },
  employee_allocation_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        fixedAsset: true
      }
    ],
    dateAndTimePicker: [
      {
        name: 'issued_at',
        Heading: 'Issued At'
      },
      {
        name: 'returned_at',
        Heading: 'Returned At'
      }
    ],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: [
          {
            label: 'Returned',
            value: 'returned'
          },
          {
            label: 'Allocated',
            value: 'allocated'
          }
        ]
      }
    ]
  },
  sales_invoice: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customDropDown: [{ name: 'status', Heading: 'Status', isMulti: true }],
    checkBox: [{ name: 'overdue', Heading: 'Overdue Invoices' }]
  },
  sales_order: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  sales_delivery: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  sales_delivery_inter_branch: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  supplier_list: {
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'email', Heading: 'Email' }]
  },
  sales_quotation: {
    textBox: [
      { name: 'reference', Heading: 'Reference' },
      { name: 'enquiry_reference', Heading: 'Enquiry Reference' }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  credit_note: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  customer: {
    textBox: [
      { name: 'email', Heading: 'Email' },
      { name: 'phone', Heading: 'Phone' }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    customDropDown: [
      {
        name: 'status',
        Heading: 'Status'
      }
    ]
  },
  category: {
    textBox: [{ name: 'name', Heading: 'Category Name' }],
    account_code: [
      { name: 'sales_account_code', Heading: 'Sales Account' },
      { name: 'inventory_account_code', Heading: 'Inventory Account' },
      { name: 'purchase_account_code', Heading: 'Cost Account' }
    ],
    category: [
      {
        name: 'category_id',
        Heading: 'Category'
      }
    ],
    customDropDown: [
      {
        name: 'product_type',
        Heading: 'Product Type'
      }
    ],
    searchEnd: true
  },
  batch_number: {
    textBox: [{ name: 'batch_number', Heading: 'Batch Number' }],
    date: [
      { name: 'manufacture_date', Heading: 'Manufacture Date' },
      { name: 'expiry_date', Heading: 'Expiry Date' }
    ]
  },
  price_list: {
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    sales_type: [{ name: 'sales_type', Heading: 'Sales Type' }]
  },
  unit_list: { textBox: [{ name: 'name', Heading: 'Name' }] },
  document_type: { textBox: [{ name: 'name', Heading: 'Name' }] },
  brand_list: { textBox: [{ name: 'name', Heading: 'Name' }] },
  manufacture_list: { textBox: [{ name: 'name', Heading: 'Name' }] },
  finance_year: {
    date: [
      { name: 'begin_date', Heading: 'Begin Date' },
      { name: 'end_date', Heading: 'End Date' }
    ]
  },
  exchange_list: {
    date: [
      { name: 'date_from', Heading: 'Date From' },
      { name: 'date_to', Heading: 'Date To' }
    ],
    currency_code: [{ name: 'currency_code', Heading: 'Currency Code' }]
  },
  voucher_inquiry: {
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    voucher_trans: [{ name: 'trans_type', Heading: 'Trans Type' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  work_order: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer', Heading: 'Customer' }]
  },
  estimations: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer', Heading: 'Customer' }]
  },
  enquiries: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [
      { name: 'reference', Heading: 'Reference' },
      { name: 'name', Heading: 'Enquiry From' },
      { name: 'phone', Heading: 'Phone' },
      { name: 'email', Heading: 'Email' }
    ]
  },
  appointments: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [
      { name: 'name', Heading: 'Name' },
      { name: 'phone', Heading: 'Phone' },
      { name: 'email', Heading: 'Email' }
    ]
  },
  sales_man: {
    textBox: [
      { name: 'email', Heading: 'Email' },
      { name: 'phone', Heading: 'Phone' }
    ],
    salesman: [{ name: 'salesman_id', Heading: 'Salesman' }]
  },
  contracts: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    date: [
      { name: 'contract_from', Heading: 'Contract From' },
      { name: 'contract_to', Heading: 'Contract To' }
    ],
    customDropDown: [{ name: 'status', Heading: 'Status' }],
    searchEnd: true
  },
  stock_kit: {
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'query', Heading: 'Name/Kit Code' }]
  },
  stock_transfer: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    wareHouse: [
      {
        name: 'from',
        Heading: 'From'
      },
      {
        name: 'to',
        Heading: 'To'
      }
    ]
  },
  item_module: {
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [
      {
        name: 'category_id',
        Heading: 'Category'
      }
    ],
    customDropDown: [
      {
        name: 'product_type',
        Heading: 'Product Type'
      }
    ],
    textBox: [{ name: 'shelf_no', Heading: 'Shelf Number' }]
  },
  purchase_request: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  material_request: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  bank_accounts_list: {
    account_code: [{ name: 'account_code', Heading: 'Back Account' }]
  },
  cost_center_list: {
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  purchase_order: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }]
  },
  purchase_order_inter_branch: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }]
  },
  users_list: {
    textBox: [{ name: 'search', Heading: 'Search' }]
  },
  cheque_list: {
    date: [
      {
        name: 'cheque_date',
        Heading: 'Cheque Date'
      },
      {
        name: 'trans_date',
        Heading: 'Transaction Date'
      }
    ],
    textBox: [{ name: 'cheque_number', Heading: 'Cheque Number' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    customDropDown: [
      {
        name: 'status',
        Heading: 'Status'
      }
    ],
    searchEnd: true
  },
  assets_list: {
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        fixedAsset: true
      }
    ],
    category: [
      { name: 'category_id', Heading: 'Category Name', fixedAsset: true }
    ],
    customDropDown: [{ name: 'fixed_asset_type', Heading: 'Asset Type' }],
    customSelect: [
      {
        name: 'fa_status',
        Heading: 'Asset Status',
        option: [
          {
            label: 'Functional',
            value: 'functional'
          },
          {
            label: 'Non Functional',
            value: 'non_functional'
          },
          {
            label: 'Under Maintenance',
            value: 'under_maintenance'
          },
          {
            label: 'Inactive',
            value: 'inactive'
          },
          {
            label: 'Damaged',
            value: 'damaged'
          }
        ]
      },
      {
        name: 'item_status',
        Heading: 'Stock Status',
        option: [
          {
            label: 'Rented Out',
            value: 'rented_out'
          },
          {
            label: 'In Stock',
            value: 'in_stock'
          },
          {
            label: 'Allocated',
            value: 'allocated'
          },
          {
            label: 'Not Purchased',
            value: 'not_purchased'
          }
        ]
      }
    ]
  },
  purchase_invoice: {
    show_multi_branch: true,
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    customDropDown: [{ name: 'status', Heading: 'Status', isMulti: true }]
  },
  purchase_invoice_inter_branch: {
    show_multi_branch: true,
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    customDropDown: [{ name: 'status', Heading: 'Status', isMulti: true }]
  },
  fixed_asset_purchase_invoice: {
    show_multi_branch: true,
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    customDropDown: [{ name: 'status', Heading: 'Status' }]
  },
  purchase_delivery: {
    show_multi_branch: true,
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_Date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }]
  },
  purchase_delivery_inter_branch: {
    show_multi_branch: true,
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_Date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }]
  },
  sales_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    checkBox: [
      { name: 'is_unpaid', Heading: 'Is Unpaid' },
      { name: 'is_recurring', Heading: 'Is Recurrent' }
    ],
    searchEnd: true
  },
  inter_branch_sales_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    checkBox: [
      { name: 'is_unpaid', Heading: 'Is Unpaid' },
      { name: 'is_recurring', Heading: 'Is Recurrent' }
    ],
    searchEnd: true
  },
  sales_order_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  inter_branch_sales_order_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  sales_delivery_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inter_branch_sales_delivery_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  sales_quotation_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  credit_note_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  category_wise_report: {
    show_multi_branch: true,
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  top_customer_report: {
    show_multi_branch: true,
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  customer_balance_summary: {
    show_multi_branch: true,
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }]
  },
  customer_balance_statement_report: {
    show_multi_branch: true,
    show_opening_balance: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    checkBox: [
      { name: 'show_opening_balance', Heading: 'Show Opening Balance' }
    ]
  },
  aged_customer_report: {
    show_multi_branch: true,
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }]
  },
  unpaid_sales_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  sales_invoice_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  inter_branch_sales_invoice_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  sales_order_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  inter_branch_sales_order_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  sales_quotation_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  sales_delivery_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  inter_branch_sales_delivery_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  credit_note_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        Heading: 'Trans Date Range'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  contract_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        Heading: 'Contract Date Range'
      }
    ],
    date: [
      { name: 'from_date', Heading: 'From Date' },
      { name: 'to_date', Heading: 'To Date' }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  rental_contract_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        Heading: 'Contract Date Range'
      }
    ],
    date: [
      { name: 'from_date', Heading: 'From Date' },
      { name: 'to_date', Heading: 'To Date' }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        fixedAsset: true
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  contract_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        Heading: 'Contract Date Range'
      }
    ],
    date: [
      { name: 'contract_from_date', Heading: 'From Date' },
      { name: 'contract_to_date', Heading: 'To Date' }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  sales_by_customer_report: {
    show_multi_branch: true,
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }]
  },
  purchase_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    checkBox: [{ name: 'is_unpaid', Heading: 'Is Unpaid' }]
  },
  inter_branch_purchase_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    checkBox: [{ name: 'is_unpaid', Heading: 'Is Unpaid' }]
  },
  purchase_order_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  purchase_order_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inter_branch_purchase_order_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inter_branch_purchase_order_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  purchase_request_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  purchase_delivery_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inter_branch_purchase_delivery_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  supplier_balance_report: {
    show_multi_branch: true,
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }]
  },
  aged_supplier_analysis_Report: {
    show_multi_branch: true,
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }]
  },
  top_supplier_purchase_report: {
    show_multi_branch: true,
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }]
  },
  category_wise_purchase_report: {
    show_multi_branch: true,
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  purchase_invoice_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  inter_branch_purchase_invoice_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  purchase_delivery_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  inter_branch_purchase_delivery_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  unpaid_purchase_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  debit_note_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  debit_note_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  ledger_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        selectThisMonth: true
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    ledger: [{ name: 'account_code', Heading: 'Choose Ledger' }],
    sub_ledger: [{ name: 'sub_ledger_code', Heading: 'Choose Sub Ledger' }],
    trans_type: [{ name: 'transaction_type', Heading: 'Transaction Type' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  profit_and_loss_statement_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  balance_sheet: {
    show_multi_branch: true,
    show_default_to_date: true,
    // dateRange: [
    //   {
    //     to: 'date_to',
    //     from: 'date_from',
    //     range: 'date_range',
    //     month: 'month'
    //   }
    // ],
    date: [{ name: 'date_to', Heading: 'Till Date' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  trail_balance_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  const_center_wise_trail_balance_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  cost_center_wise_profit_and_loss_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  schedule_maintenance_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  asset_wise_profit_summary_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        fixedAsset: true
      }
    ],
    category: [
      { name: 'category_id', Heading: 'Category Name', fixedAsset: true }
    ]
  },
  asset_transaction_report: {
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        fixedAsset: true
      }
    ],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  asset_stock_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        fixedAsset: true
      }
    ],
    customDropDown: [{ name: 'item_status', Heading: 'Stock Status' }]
  },
  email_logs: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    trans_type: [{ name: 'trans_type', Heading: 'Trans Type' }]
  },
  journal_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    ledger: [{ name: 'account_code', Heading: 'Choose Ledger' }]
  },
  customer_payment_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  supplier_payment_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  bank_balance_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  void_transaction_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    trans_type: [{ name: 'transaction_type', Heading: 'Trans Type' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  recurrent_profile_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    trans_type: [{ name: 'transaction_type', Heading: 'Transaction Type' }]
  },
  receipt_voucher_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    bankAccount: [{ name: 'to_bank_id', Heading: 'To Bank' }]
  },
  payment_voucher_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    bankAccount: [{ name: 'from_bank_id', Heading: 'From Bank' }]
  },
  bank_transfer_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    bankAccount: [
      { name: 'from_bank_id', Heading: 'From Bank' },
      { name: 'to_bank_id', Heading: 'To Bank' }
    ]
  },
  voucher_inquiry_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    trans_type: [{ name: 'trans_type', Heading: 'Transaction Type' }]
  },
  refund_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    bankAccount: [{ name: 'from_bank_id', Heading: 'From Bank' }]
  },
  inventory_valuation_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  batch_report: {
    date: [
      { name: 'exp_date_from', Heading: 'Expiry Date From' },
      { name: 'exp_date_to', Heading: 'Expiry Date To' }
    ],
    checkBox: [
      { name: 'is_expiry', Heading: 'Is Expired' },
      { name: 'is_active', Heading: 'Is Active' }
    ]
  },
  inventory_status_report: {
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  inventory_movement_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inventory_summary_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  inventory_purchase_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    category: [{ name: 'category_id', Heading: 'Category Name' }],
    searchEnd: true
  },
  invoice_wise_item_profit_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    category: [{ name: 'category_id', Heading: 'Category Name' }],
    searchEnd: true
  },
  item_reorder_report: {
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  item_wise_profit_summary_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  category_wise_profit_summary_report: {
    show_multi_branch: true,
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  supplier_balance_statement_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    show_multi_branch: true
  },
  bank_transaction_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    bankAccount: [{ name: 'bank_account_id', Heading: 'Bank' }],
    trans_type: [{ name: 'transaction_type', Heading: 'Trans Type' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  payment_list_term: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  terms_and_conditions: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  shipping_company_list: {
    textBox: [
      { name: 'name', Heading: 'Name' },
      { name: 'contact_number', Heading: 'Contact Number' },
      { name: 'person', Heading: 'Person' }
    ]
  },
  warehouse_list: {
    textBox: [
      { name: 'location_name', Heading: 'Location Name' },
      { name: 'email', Heading: 'Email' },
      { name: 'contact_number', Heading: 'Contract Number' }
    ]
  },
  tax_list: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  tax_group_list: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  tax_group_item_list: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  monthly_trail_balance_report: {
    show_multi_branch: true,
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    months: [{ name: 'months', Heading: 'Select Month' }]
  },
  year_wise_trail_balance_report: {
    show_multi_branch: true,
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    years: [{ name: 'years', Heading: 'Select Year' }]
  },
  year_wise_profit_and_loss_report: {
    show_multi_branch: true,
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    years: [{ name: 'years', Heading: 'Select Year' }]
  },
  warehouse_wise_average_cost: {
    wareHouse: [
      {
        name: 'warehouse_id',
        Heading: 'Warehouse Name'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  notification_filter: {
    priority: [
      {
        name: 'priority',
        Heading: 'Priority'
      }
    ]
  },
  work_in_progress_report: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  finished_work_orders_report: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  work_orders_details_report: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  over_due_work_orders_report: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  work_orders_issued_items_report: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    work_order: [{ name: 'work_order_id', Heading: 'Work Order' }]
  },
  work_orders_report: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  work_orders_comparison_report: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  enquiry_report: {
    textBox: [
      { name: 'reference', Heading: 'Reference' },
      { name: 'email', Heading: 'Email' },
      { name: 'phone', Heading: 'Phone' }
    ],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  estimation_report: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  vat_report: {
    date: [
      { name: 'date_from', Heading: 'From Date' },
      { name: 'date_to', Heading: 'To Date' }
    ]
  },
  sales_order_inter_branch: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  sales_invoice_inter_branch: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customDropDown: [{ name: 'status', Heading: 'Status' }]
  },
  asset_purchase_report: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'purchase_reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    customDropDown: [{ name: 'fixed_asset_type', Heading: 'Asset Type' }]
  },
  customer_group: {
    textBox: [{ name: 'name', Heading: 'Name' }],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }]
  },
  sales_type: {
    textBox: [{ name: 'name', Heading: 'Name' }],
    customDropDown: [
      {
        name: 'tax_included',
        Heading: 'Tax Included'
      }
    ]
  },
  sales_area: {
    textBox: [{ name: 'area_code', Heading: 'Area Code' }]
  },
  cash_handover: {
    user: [
      {
        name: 'requested_by',
        Heading: 'Requeted By'
      }
    ],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customDropDown: [{ name: 'status', Heading: 'Status' }],
    textBox: [{ name: 'amount', Heading: 'Amount' }]
  }
};

export const SelectedFilterFormData = module => {
  return FilterOptions[module];
};
