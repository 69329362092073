import { useEffect } from 'react';

const useFormTabNavigator = (
  className,
  otherElementsClassList,
  autoFocusFirstElement
) => {
  // Ensure otherElementsClassList is an array
  const classNamesArray = Array.isArray(otherElementsClassList)
    ? otherElementsClassList
    : typeof otherElementsClassList === 'string'
    ? [otherElementsClassList]
    : [];

  let modalIsExist = document.querySelector('.modal-dialog');
  const isVisibleAndEnabled = element => {
    // Check if the element is visible and not disabled
    let isVisible = true;
    if (modalIsExist && !element?.closest('.modal-dialog')) {
      isVisible = false;
    }

    return (
      isVisible &&
      element.offsetWidth > 0 &&
      element.offsetHeight > 0 &&
      window.getComputedStyle(element).display !== 'none' &&
      !element.disabled // Exclude disabled elements
    );
  };

  useEffect(() => {
    // Add class to other elements
    classNamesArray.forEach(elementClassName => {
      const elementsList = document.querySelectorAll(`.${elementClassName}`);
      elementsList.forEach(element => element.classList.add(className));
    });

    const handleFormElementTabSwitch = event => {
      const { key } = event;

      if (key === 'Tab') {
        event.preventDefault();

        // update modalIsExist when each tab switch
        modalIsExist = document.querySelector('.modal-dialog');

        // Get all visible and enabled elements
        const elements = Array.from(
          document.getElementsByClassName(className)
        ).filter(isVisibleAndEnabled);

        const currentElement = document.activeElement; // Use document.activeElement
        let nextIndex = currentElement
          ? elements.findIndex(el => el === currentElement)
          : -1;

        // Adjust nextIndex based on the shift key
        if (event.shiftKey) {
          nextIndex = (nextIndex - 1 + elements.length) % elements.length; // Navigate backward
        } else {
          nextIndex = (nextIndex + 1) % elements.length; // Navigate forward
        }

        const nextFocusableElement = elements[nextIndex];
        nextFocusableElement?.focus();
      }
    };

    // Auto-focus the first element if the prop is true
    if (autoFocusFirstElement) {
      const elements = document.getElementsByClassName(className);
      const visibleElements = Array.from(elements).filter(isVisibleAndEnabled);
      if (visibleElements.length > 0) {
        visibleElements[0].focus();
      }
    }

    window.addEventListener('keydown', handleFormElementTabSwitch);

    return () => {
      window.removeEventListener('keydown', handleFormElementTabSwitch);

      // Clean up by removing the class when unmounting
      classNamesArray.forEach(elementClassName => {
        const elementsList = document.querySelectorAll(`.${elementClassName}`);
        elementsList.forEach(element => element.classList.remove(className));
      });
    };
  }, [className, classNamesArray, autoFocusFirstElement, modalIsExist]);

  return null;
};

export default useFormTabNavigator;
