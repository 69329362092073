import axios from 'axios';
import PropTypes from 'prop-types';
export default function getAccountCurrencyDetails({ role, targetId, date }) {
  return new Promise((resolve, reject) => {
    const missingProps = [];

    if (!role) {
      missingProps.push('role');
    }

    if (!targetId) {
      missingProps.push('targetId');
    }

    if (!date) {
      missingProps.push('date');
    }

    if (missingProps?.length > 0) {
      reject({
        message: `The ${missingProps?.toString()} ${
          missingProps.length > 1 ? 'properties are' : 'property is'
        } required.`
      });
      return;
    }

    axios
      .get(`get-currency-rate/${role}`, {
        params: {
          entity_id: targetId,
          date: date
        }
      })
      .then(response => {
        const currencyDetails = response?.data?.data;
        if (currencyDetails) {
          resolve(currencyDetails);
        }

        reject({
          message: ''
        });
      })
      .catch(error => {
        reject(error);
      });
  });
}

getAccountCurrencyDetails.propTypes = {
  role: PropTypes.oneOf(['SUPPLIER', 'CUSTOMER']).isRequired,
  targetId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};
