import { React, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { MdAddCircle } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';

import generateFormDataHelper from 'helpers/generateFormDataHelper';
import SelectItem from 'components/form/SelectItem';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import TableLoading from 'module/Report/Common/TableLoading';
import { showToast } from 'module/Common/Toast/toast';
import { BsExclamationCircleFill } from 'react-icons/bs';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectBatch from 'components/form/SelectBatch';
import useAxisproPermission from 'hooks/useAxisproPermission';
import classNames from 'classnames';
import ItemCodeAutoComplete from 'components/form/ItemCodeAutoComplete';
import SelectTaxGroup from 'components/form/SelectTaxGroup';
import InventoryMomentHistoryModal from 'components/purchase-history-modal/InventoryMomentHistoryModal';
import { DeleteContext } from 'context/Context';
import handleSubmitOnKeyPress from 'helpers/handleSubmitOnKeyPress';
import ShowInput from 'components/form-settings-offcanvas/ShowInput';
import useFormSettingsHook from 'hooks/useFormSettingsHook';

function PurchaseTableForm({
  editItemData,
  onTableItemChange,
  conversion,
  transaction_date,
  taxIncluded,
  defaultKeys,
  isLoading,
  enableBatchSection,
  batchIsEditable,
  hide,
  transType,
  enableTaxGroup,
  supplierDetails,
  showPurchaseHistory,
  setIncludeRoundOffAmount
}) {
  const axisProPermission = useAxisproPermission();
  const Translate = useAxisproTranslate();

  const { dispatch: configConfirm } = useContext(DeleteContext);
  const { settings: Form_settings } = useFormSettingsHook(transType);
  const submitButtonRef = useRef(null);
  const itemCodeElementRef = useRef(null);
  const [tableData, setTableData] = useState(
    editItemData ? editItemData : defaultKeys
  );

  const itemIsKit = ['1', 'true'].tableData?.is_kit?.toString()?.toLowerCase();

  const showPurchaseHistoryModal =
    showPurchaseHistory && supplierDetails?.id && tableData?.stock_id;

  const focusItemCode = !isLoading && editItemData;

  const handleFieldChange = (e, action) => {
    let newData = generateFormDataHelper(e, action, tableData);
    let keyName = !action ? e.target.name : action?.name;

    if (keyName === 'discount_unit_ref') {
      newData.discount_amount = 0;
      newData.discount_percent = 0;
    }

    if (keyName === 'discount_amount') {
      newData.discount_percent = 0;
    }

    if (keyName === 'discount_percent') {
      newData.discount_amount = 0;
    }

    if (keyName === 'item_display_name') {
      if (enableTaxGroup) {
        let taxGroupDetails = {};
        if (supplierDetails?.tax_config === 'USE_ITEM_TAX') {
          taxGroupDetails = newData?.item_display_name_ref?.item;
        } else {
          const supplierTaxConfigTaxGroup =
            supplierDetails?.tax_config_tax_group;
          taxGroupDetails.tax_group_id = supplierTaxConfigTaxGroup?.id;
          taxGroupDetails.tax_group_name = supplierTaxConfigTaxGroup?.name;
        }

        newData.tax_group_id = taxGroupDetails?.tax_group_id;
        newData.tax_group_id_ref = {
          label: taxGroupDetails?.tax_group_name,
          value: taxGroupDetails?.tax_group_id
        };
      }

      if (enableBatchSection) {
        newData.has_batch_inventory = e?.item?.has_batch_inventory;
      }
      newData.is_kit = e?.item?.type === 'kit' ? 1 : 0;
      newData.stock_id = e ? e.item.stock_id : '';
      newData.unit_price = e
        ? editItemData && editItemData.stock_id === e.item.stock_id
          ? editItemData.unit_price
          : e.item.purchase_rate
        : '';
      newData.purchase_rate_ref = e?.item?.purchase_rate;
      newData.purchase_rate_formatted_ref = e?.item?.purchase_rate_formatted;
      newData.unit_ref = newData.is_kit ? 'KIT' : e?.item?.unit_name ?? '';
      newData.quantity = e
        ? editItemData && editItemData.stock_id === e.item.stock_id
          ? editItemData.quantity
          : 1
        : '';
      newData.discount_amount = e
        ? editItemData && editItemData.stock_id === e.item.stock_id
          ? editItemData.discount_amount
          : 0
        : 0;
      newData.discount_percent = e
        ? editItemData && editItemData.stock_id === e.item.stock_id
          ? editItemData.discount_percent
          : 0
        : 0;

      newData.item_description =
        newData?.item_display_name_ref?.item?.item_description || '';
    }

    if (['unit_price', 'item_display_name'].includes(keyName)) {
      newData.skip_unit_price_warning_ref = false;
    }

    setTableData(newData);
  };

  const _handleSubmitOnKeyPress = (event, key) => {
    handleSubmitOnKeyPress(event, key, ['item-select-autocomplete'], () => {
      if (submitButtonRef?.current && !submitButtonRef.current?.disabled) {
        event?.stopPropagation();
        submitButtonRef.current.click();
      }
    });
  };

  const handleSubmit = e => {
    e?.preventDefault();
    if (hide) return;
    if (typeof setIncludeRoundOffAmount === 'function')
      setIncludeRoundOffAmount(true);
    let error =
      Form_settings?.item_description && !tableData?.item_description?.trim()
        ? Translate('The item description field is required.')
        : Form_settings?.tax_group && !tableData?.tax_group_id?.trim()
        ? Translate('The tax group field is required.')
        : tableData.quantity <= 0
        ? Translate('Invalid quantity, value must be greater than 0')
        : conversion &&
          parseFloat(tableData.quantity_ref) < parseFloat(tableData.quantity)
        ? `${Translate(
            'Invalid quantity, value must be less than or equal to'
          )} ${tableData.quantity_ref}`
        : parseFloat(tableData.discount_amount) < 0
        ? Translate(
            'Invalid discount amount, the amount must be between 0 and unit price'
          )
        : tableData.discount_unit_ref === '$' &&
          parseFloat(tableData.discount_amount) >
            parseFloat(tableData.unit_price)
        ? Translate(
            'Invalid discount amount, the amount must be less than or equal to the unit price'
          )
        : (tableData.discount_unit_ref === '%' &&
            parseFloat(tableData.discount_percent) > 100) ||
          parseFloat(tableData.discount_percent) < 0
        ? Translate(
            'Invalid discount percentage, the percentage must be between 0 and 100'
          )
        : Boolean(Number(tableData?.has_batch_inventory)) &&
          !tableData?.batch_number
        ? Translate('Invalid batch, The batch number field is required.')
        : null;

    //  edit case validation
    // if (!conversion && tableData?.quantity > 0) {
    //   let quantity_value = parseFloat(tableData.quantity);
    //   error =
    //     editItemData?.quantity_issued > 0 &&
    //     quantity_value < parseFloat(editItemData.quantity_issued)
    //       ? Translate(
    //           'Invalid quantity, value cannot be reduced below the already issued quantity of '
    //         ) + editItemData.quantity_issued
    //       : editItemData?.qty_sent > 0 &&
    //         quantity_value < parseFloat(editItemData.qty_sent)
    //       ? Translate(
    //           'Invalid quantity, value cannot be reduced below the already sent quantity of '
    //         ) + editItemData.qty_sent
    //       : editItemData?.qty_invoiced > 0 &&
    //         quantity_value < parseFloat(editItemData.qty_invoiced)
    //       ? Translate(
    //           'Invalid quantity, value cannot be reduced below the already invoiced quantity of '
    //         ) + editItemData.qty_invoiced
    //       : null;
    // }

    // check if the unit price is greater than the item master purchase rate
    const itemPurchaseRate = parseFloat(tableData?.purchase_rate_ref);
    const itemUnitRate = parseFloat(tableData?.unit_price);
    if (
      transType === 'PI' &&
      !tableData?.skip_unit_price_warning_ref &&
      itemPurchaseRate > 0 &&
      itemUnitRate > itemPurchaseRate
    ) {
      configConfirm({
        type: 'CONFIG',
        payload: {
          show: true,
          title: 'Warning!',
          message: `Provided unit price is higher than purchase rate. The actual
              purchase rate is ${tableData?.purchase_rate_formatted_ref}, are
              you sure you want to continue?`,
          submitButtonVariant: 'warning',
          buttonText: Translate('Continue'),
          cancelButtonVariant: 'secondary',
          onSubmit: event => {
            event?.preventDefault();
            handleAddOrUpdate(error, {
              ...tableData,
              skip_unit_price_warning_ref: true
            });
            configConfirm({
              type: 'RESET'
            });
          }
        }
      });
      return;
    }
    handleAddOrUpdate(error, tableData);
  };

  const handleAddOrUpdate = (error, data) => {
    //remove null data from data
    const dataKeysArray = Object.keys(data);

    if (Array.isArray(dataKeysArray)) {
      dataKeysArray.map(key => {
        if (data?.[key] === null) {
          data[key] = '';
        }
      });
    }

    if (error) {
      showToast(error, 'error');
    } else if (!editItemData) {
      onTableItemChange(
        {
          ...data,
          add_to_cart_ref: true,
          edit: true,
          trans_date: transaction_date,
          tax_included: taxIncluded ? 1 : 0
        },
        'ADD',
        true
      );
      setTableData(defaultKeys);
    } else {
      onTableItemChange(
        {
          ...data,
          add_to_cart_ref: true,
          edit: true,
          trans_date: transaction_date,
          tax_included: taxIncluded ? 1 : 0,
          removeThisKeys: ['errors']
        },
        'UPDATE',
        true
      );
    }
  };

  useEffect(() => {
    if (itemCodeElementRef?.current && !hide) {
      setTimeout(() => {
        itemCodeElementRef.current?.focus();
      }, 10);
    }
  }, [hide, itemCodeElementRef]);

  useEffect(() => {
    setTableData(editItemData ? editItemData : defaultKeys);
  }, [supplierDetails?.id]);

  return (
    <>
      {isLoading ? (
        <TableLoading
          row={1}
          column={
            enableBatchSection && enableTaxGroup
              ? 12
              : enableBatchSection || enableTaxGroup
              ? 11
              : 10
          }
        />
      ) : (
        <tr
          className={classNames({ 'd-none': hide })}
          style={{ borderBottom: editItemData ? '' : '1.5px dashed #ccc' }}
        >
          <td className="fs--1 p-1 text-center">
            {editItemData && editItemData.errors ? (
              <BsExclamationCircleFill size={13} className="text-danger" />
            ) : (
              '#'
            )}
          </td>
          <ShowInput show={!Form_settings || Form_settings?.item_code}>
            <td className="p-1 fs--1">
              {conversion ? (
                tableData.stock_id
              ) : (
                <ItemCodeAutoComplete
                  autoFocus={focusItemCode}
                  className="form-tab-switch-control"
                  type="text"
                  name="stock_id"
                  id="stock_id"
                  keyName="item_name"
                  SelectItemKeyName="item_display_name"
                  value={tableData.stock_id}
                  onChange={handleFieldChange}
                  onKeyDown={event => _handleSubmitOnKeyPress(event, 'enter')}
                  filter_keys={{
                    exclude_kits: true
                  }}
                />
              )}
            </td>
          </ShowInput>

          <td className="p-1 fs--1" style={{ maxWidth: 300 }}>
            {conversion ? (
              tableData.item_display_name ? (
                <span>{tableData.item_display_name}</span>
              ) : null
            ) : (
              <>
                <SelectItem
                  value={tableData.item_display_name_ref}
                  handleFieldChange={handleFieldChange}
                  name="item_display_name"
                  keyName="item_name"
                  advanceSearch
                  advanceSearchType="purchase"
                  placeholder={Translate('Search...')}
                  onKeyDown={e => _handleSubmitOnKeyPress(e, 'enter')}
                  style={{ borderRadius: 'none' }}
                  withAddButton={axisProPermission('create-item')}
                  filter_keys={{
                    exclude_kits: true
                  }}
                  includeDetails
                />

                {showPurchaseHistoryModal ? (
                  <InventoryMomentHistoryModal
                    id={supplierDetails?.id}
                    stockId={tableData?.stock_id}
                  />
                ) : null}
              </>
            )}

            <ShowInput show={!Form_settings || Form_settings?.item_description}>
              <Form.Control
                id="item_description"
                as="textarea"
                placeholder={Translate('Enter Description...')}
                rows={2}
                name="item_description"
                onChange={handleFieldChange}
                value={tableData.item_description}
                className={`form-tab-switch-control ${
                  showPurchaseHistoryModal ? '' : 'mt-1'
                }`}
              />
            </ShowInput>
          </td>

          <ShowInput show={Boolean(enableBatchSection)}>
            <td className="p-1 fs--1" style={{ maxWidth: 200 }}>
              {Boolean(Number(tableData?.has_batch_inventory)) ||
              tableData?.batch_number_display?.length > 0 ? (
                !batchIsEditable && conversion ? (
                  tableData.batch_number_display
                ) : (
                  <SelectBatch
                    value={tableData.batch_number_ref}
                    stock_id={tableData?.stock_id}
                    handleFieldChange={handleFieldChange}
                    name="batch_number"
                    keyName="batch_number"
                    placeholder={Translate('Search...')}
                    isDisabled={!tableData?.stock_id}
                    withAddButton
                  />
                )
              ) : (
                '-'
              )}
            </td>
          </ShowInput>

          <ShowInput show={!Form_settings || Form_settings?.unit}>
            <td className="p-1 fs--1 text-uppercase">
              {tableData?.unit_ref
                ? tableData.unit_ref
                : tableData?.unit_name
                ? tableData.unit_name
                : '-'}
            </td>
          </ShowInput>

          <ShowInput show={Boolean(enableTaxGroup)}>
            <td style={{ maxWidth: 200 }}>
              <SelectTaxGroup
                withTaxDetails
                name="tax_group_id"
                placeholder={Translate('Search...')}
                value={tableData?.tax_group_id_ref}
                onChange={handleFieldChange}
                isClearable={false}
              />
            </td>
          </ShowInput>

          <td className="p-1 fs--1">
            <Form.Control
              type="number"
              name="quantity"
              onChange={handleFieldChange}
              onKeyDown={event => _handleSubmitOnKeyPress(event, 'enter')}
              min={1}
              max={conversion ? tableData.quantity_ref : undefined}
              value={tableData.quantity}
              className="form-tab-switch-control"
            />
          </td>

          <td className="p-1 fs--1">
            {(conversion && transType === 'DN') || itemIsKit ? (
              tableData.unit_price + '/-'
            ) : (
              <Form.Control
                type="number"
                step="0.1"
                min={0}
                name="unit_price"
                onChange={handleFieldChange}
                onKeyDown={event => _handleSubmitOnKeyPress(event, 'enter')}
                value={tableData.unit_price}
                className="form-tab-switch-control"
              />
            )}
          </td>

          <ShowInput show={!Form_settings || Form_settings?.unit_tax}>
            <td className="p-1 fw-bold fs--1 text-uppercase"></td>
          </ShowInput>

          <ShowInput show={!Form_settings || Form_settings?.discount}>
            <td className="p-1 fs--1">
              {(conversion && transType === 'DN') || itemIsKit ? (
                tableData.discount_amount + '/-'
              ) : (
                <div className="d-flex flex-row ">
                  <Form.Select
                    className="bg-primary text-white border-primary form-tab-switch-control"
                    aria-label="Discount unit"
                    style={{ width: '80px' }}
                    name="discount_unit_ref"
                    onChange={handleFieldChange}
                    onKeyDown={event => _handleSubmitOnKeyPress(event, 'enter')}
                    value={tableData.discount_unit_ref}
                  >
                    <option value="$">$</option>
                    <option value="%">%</option>
                  </Form.Select>
                  <Form.Control
                    className="w-100 form-tab-switch-control"
                    type="number"
                    step="0.1"
                    min={0}
                    max={
                      tableData.discount_unit_ref === '%'
                        ? 100
                        : tableData.unit_price > 0
                        ? tableData.unit_price
                        : 0
                    }
                    name={
                      tableData.discount_unit_ref === '%'
                        ? 'discount_percent'
                        : 'discount_amount'
                    }
                    onChange={handleFieldChange}
                    onKeyDown={event => _handleSubmitOnKeyPress(event, 'tab')}
                    value={
                      tableData[
                        tableData.discount_unit_ref === '%'
                          ? 'discount_percent'
                          : 'discount_amount'
                      ]
                    }
                  />
                </div>
              )}
            </td>
          </ShowInput>

          <td className="p-1 fw-bold fs--1 text-uppercase"></td>
          <td>
            <div className="d-flex flex-row justify-content-center w-100">
              <Button
                ref={submitButtonRef}
                variant="transparent"
                type="button"
                onClick={handleSubmit}
                className="p-0 text-success"
                style={{ boxShadow: 'none' }}
                disabled={!tableData.item_display_name}
              >
                {editItemData ? (
                  <IoMdCheckmarkCircle size={20} />
                ) : (
                  <MdAddCircle size={20} />
                )}
              </Button>
              {editItemData && (
                <Button
                  size="sm"
                  variant="transparent"
                  className="p-0 ms-1 text-danger"
                  onClick={() =>
                    onTableItemChange(
                      { ...editItemData, removeThisKeys: ['edit'] },
                      'UPDATE'
                    )
                  }
                >
                  <AiFillCloseCircle size={19} />
                </Button>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

PurchaseTableForm.propTypes = {
  editItemData: PropTypes.object,
  onTableItemChange: PropTypes.func,
  conversion: PropTypes.bool,
  transaction_date: PropTypes.string,
  taxIncluded: PropTypes.bool,
  defaultKeys: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  enableBatchSection: PropTypes.bool,
  batchIsEditable: PropTypes.bool,
  hide: PropTypes.bool,
  transType: PropTypes.string,
  enableTaxGroup: PropTypes.bool,
  supplierDetails: PropTypes.object,
  showPurchaseHistory: PropTypes.bool,
  setIncludeRoundOffAmount: PropTypes.func
};

export default PurchaseTableForm;
