import React, { useContext, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import { FaCalendar, FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { AuthWizardContext } from 'context/Context';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useState } from 'react';

const AppDatePicker = ({
  value,
  name,
  className,
  calendarClassName,
  onChange,
  isInvalid,
  format,
  showDefaultDate,
  datePickersRefs,
  disabled,
  ...rest
}) => {
  let { user } = useContext(AuthWizardContext);
  const [defaultDateSetupCompleted, setDefaultDateSetupCompleted] =
    useState(false);
  const handleFieldChange = (date, name) => {
    let formatedDate = date
      ? date.getFullYear().toString().padStart(4, '0') +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2)
      : null;

    onChange({ target: { name: name, value: formatedDate } });
  };

  const setDateFomat = format => {
    const dateFormatArrayWithAllowdkeysObject = {
      dd: 'dd',
      mm: 'MM',
      MM: 'MM',
      yyyy: 'y',
      y: 'y'
    };

    let dateFormat = 'y-MM-dd';
    let formatItemKeysArray =
      typeof format === 'string' ? format.split('-') : [];
    if (
      formatItemKeysArray.length === 3 &&
      formatItemKeysArray.includes('dd') &&
      (formatItemKeysArray.includes('mm') ||
        formatItemKeysArray.includes('MM')) &&
      (formatItemKeysArray.includes('yyyy') ||
        formatItemKeysArray.includes('y'))
    ) {
      let newFormatArrayWithAllowdkeys = [];
      formatItemKeysArray.map(key =>
        newFormatArrayWithAllowdkeys.push(
          dateFormatArrayWithAllowdkeysObject[key]
        )
      );
      dateFormat = newFormatArrayWithAllowdkeys.join('-');
    }
    return dateFormat;
  };

  function isValidDate(value) {
    const year = value.split('-')[0];
    if (!year || year < 1000) {
      return false;
    }

    return value;
  }

  useEffect(() => {
    if (showDefaultDate && !value && !defaultDateSetupCompleted) {
      handleFieldChange(new Date(), name);
    }
    setDefaultDateSetupCompleted(true);
  }, [showDefaultDate, defaultDateSetupCompleted, value]);

  return (
    <DatePicker
      ref={datePickersRefs}
      className={`custome-date-picker${className ? ' ' + className : ''}${
        isInvalid ? ' is-invalid ' : ''
      }`}
      calendarClassName={`custome-date-picker-calendar ${calendarClassName}`}
      value={
        value && isValidDate(value)
          ? new Date(value)
          : showDefaultDate && value !== null
          ? new Date()
          : null
      }
      calendarIcon={!value ? <FaCalendar size={12} color="#b6c1d2" /> : null}
      clearIcon={
        value && !disabled ? <FaTimes size={12} color="#b6c1d2" /> : null
      }
      disabled={disabled}
      onChange={date => handleFieldChange(date, name)}
      format={setDateFomat(
        format
          ? format
          : user && user.branch && user.branch.date_format
          ? user.branch.date_format
          : 'y-MM-dd'
      )}
      {...rest}
    />
  );
};

AppDatePicker.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  format: PropTypes.string,
  datePickersRefs: PropTypes.any,
  showDefaultDate: PropTypes.bool,
  calendarClassName: PropTypes.string,
  disabled: PropTypes.bool
};

export default AppDatePicker;
