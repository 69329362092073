import { React, useState } from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';
import ReactSelectAddButton from 'components/add-button/ReactSelectAddButton';
import AddCreditStatus from 'module/Common/Forms/CreditStatus/AddCreditStatus';

const SelectCreditStatus = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  onFocus,
  withAddButton
}) => {
  const [showFormWindow, setShowFormWindow] = useState(false);
  const { setDefaultResult, loading, defaultOptions, setDefaultOptions } =
    useAutoCompleteApiCallHandler({
      url: 'sales/credit_status/autocomplete',
      setOptions: option => ({
        label: option.description,
        value: option.id
      }),
      onFocus: onFocus
    });

  return (
    <>
      <Select
        isClearable
        isLoading={loading}
        placeholder={placeholder ?? ''}
        options={defaultOptions}
        onFocus={setDefaultResult}
        value={value}
        onChange={handleFieldChange}
        name={name ?? 'credit_status_id'}
        classNamePrefix="app-react-select"
        className={error && 'form-control ps-0 py-0 is-invalid auto-select'}
        components={
          withAddButton
            ? {
                Menu: props => (
                  <>
                    <ReactSelectAddButton
                      props={props}
                      setShowFormWindow={() => setShowFormWindow(true)}
                      title="Add Credit Status"
                    />
                  </>
                )
              }
            : null
        }
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 }),
          control: provided => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
      />
      {withAddButton && (
        <AddCreditStatus
          show={showFormWindow}
          onHide={() => setShowFormWindow(false)}
          setData={handleFieldChange}
          callBack={newItem => {
            if (newItem?.id) {
              setDefaultOptions(prev => [
                {
                  label: newItem.description,
                  value: newItem.id
                },
                ...prev
              ]);
            }
          }}
        />
      )}
    </>
  );
};

SelectCreditStatus.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.number,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  withAddButton: PropTypes.bool
};

export default SelectCreditStatus;
