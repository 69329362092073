import { React, useState } from 'react';
import { Alert, Container, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaEye, FaFilePdf, FaImage, FaTrashAlt } from 'react-icons/fa';
import { FcPlus } from 'react-icons/fc';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import DeleteWarning from 'module/Common/Warnings/DeleteWarning';

function CustomerDocuments({ documents, fetchData, setKey, uploadKYC }) {
  const Translate = useAxisproTranslate();
  const [deleteModal, setDeleteMoadl] = useState(false);
  const [attachmentId, setAttachmentId] = useState();

  function isImage(fileLink) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const extension = fileLink.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
  }

  function isPDF(fileLink) {
    const pdfExtensions = ['pdf'];
    const extension = fileLink.split('.').pop().toLowerCase();
    return pdfExtensions.includes(extension);
  }

  function ViewImage(imageLink) {
    window.open(imageLink, '_blank');
  }

  const handleDelete = id => {
    setAttachmentId(id);
    setDeleteMoadl(true);
  };

  return (
    <>
      <div className="text-end pt-1">
        <FcPlus
          size={20}
          className="cursor-pointer me-3"
          title="Add KYC documents"
          onClick={uploadKYC}
        />
      </div>
      <Container className="p-0 customer-documents-style">
        <Table striped bordered hover responsive className="mt-2" size="sm">
          <thead className="bg-dark text-light text-uppercase">
            <tr>
              <th style={{ width: '50px' }}>#</th>
              <th className="text-center">{Translate('DOCUMENT TYPE')}</th>
              <th className="d-flex align-items-center justify-content-start">
                {Translate('FILE NAME')}
              </th>
              <th style={{ width: '50px' }} className="text-center">
                {Translate('ACTION')}
              </th>
            </tr>
          </thead>
          {documents?.length > 0 ? (
            documents.map((item, index) => (
              <tbody key={index}>
                <tr>
                  <td className="d-flex align-items-center text-dark">
                    {index + 1}
                  </td>
                  <td className="text-uppercase text-center text-dark">
                    {item?.document_type ?? '-'}
                  </td>
                  <td className="text-dark fs--1">{item?.file_name ?? '-'}</td>
                  {isImage(item?.file_path) ? (
                    <td className="text-center cursor-pointer d-flex align-items-center">
                      <FaImage
                        className="ms-3 text-primary"
                        size={15}
                        onClick={() => {
                          ViewImage(item.file_path);
                        }}
                      />
                      <FaTrashAlt
                        className="text-danger ms-3"
                        size={12}
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </td>
                  ) : isPDF(item.file_path) ? (
                    <td className="text-center cursor-pointer d-flex align-items-center">
                      <FaFilePdf
                        className="ms-3 text-dark"
                        size={15}
                        onClick={() => {
                          ViewImage(item.file_path);
                        }}
                      />
                      <FaTrashAlt
                        className="text-danger ms-3"
                        size={12}
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </td>
                  ) : (
                    <td className="text-center cursor-pointer d-flex align-items-center">
                      <FaEye
                        className="ms-3 text-info"
                        size={15}
                        onClick={() => {
                          ViewImage(item.file_path);
                        }}
                      />
                      <FaTrashAlt
                        className="text-danger ms-3"
                        size={12}
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            ))
          ) : (
            <tbody>
              <tr className="alert-styles">
                <td colSpan={12}>
                  <Alert variant="warning">
                    {Translate('Currently there is no report generated.')}
                  </Alert>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </Container>
      <DeleteWarning
        show={deleteModal}
        id={attachmentId}
        onHide={() => setDeleteMoadl(false)}
        fetchData={fetchData}
        data={documents}
        setKey={() => setKey('kyc')}
        heading={'Delete document'}
        message={'Are you sure? You want to delete this document!'}
        type={'ATTACHMENT'}
        url="documents"
      />
    </>
  );
}

CustomerDocuments.propTypes = {
  setKey: PropTypes.func,
  fetchData: PropTypes.func,
  uploadKYC: PropTypes.func,
  documents: PropTypes.any
};

export default CustomerDocuments;
