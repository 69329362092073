import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function ChequeNumberInputField({
  title,
  handleFieldChange,
  formError,
  formData,
  disabled = false,
  groupAs = Row, // Row / Col
  labelCol = 5,
  inputCol = 5,
  labelColumn = true
}) {
  const handleChange = e => {
    e.target.value = e.target.value.replace(/\s+/g, '');
    let inputValue = e.target.value;
    let pattern = /^\d*$/;
    if (pattern.test(inputValue)) {
      handleFieldChange(e);
    }
  };

  return (
    <Form.Group as={groupAs} className="mb-3" controlId="chequeNumber">
      <Form.Label column={labelColumn} sm={labelCol} className="require-data">
        {title}
      </Form.Label>
      <Col sm={inputCol}>
        <Form.Control
          name="cheque_number"
          className="form-tab-switch-control"
          value={formData.cheque_number}
          onChange={handleChange}
          type="number"
          min={0}
          onKeyDown={e =>
            ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          inputMode="numeric" // for mobile keyboard
          disabled={disabled}
        />
      </Col>
      <Col
        xl={1}
        md={1}
        sm={1}
        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
      >
        <FormErrorPopover
          id="cheque_number"
          totalErrorCount={Object.keys(formError).length}
          errorMessage={formError.cheque_number}
        />
      </Col>
    </Form.Group>
  );
}

ChequeNumberInputField.propTypes = {
  title: PropTypes.any,
  formData: PropTypes.any,
  handleFieldChange: PropTypes.func,
  formError: PropTypes.any,
  disabled: PropTypes.bool,
  groupAs: PropTypes.any,
  labelCol: PropTypes.number,
  inputCol: PropTypes.number,
  labelColumn: PropTypes.bool
};

export default ChequeNumberInputField;
