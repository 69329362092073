import { React } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectBankAccount = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  onFocus,
  isClearable,
  withAllOption,
  additionalKey
}) => {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: 'finance/bank-accounts-autocomplete',
      setParams: searchKey => ({ name: searchKey }),
      setOptions: option => ({
        label: option.bank_name,
        value: keyName ? option[keyName] : option.id,
        code: additionalKey ? option[additionalKey] : ''
      }),
      onFocus: onFocus,
      staticOptions: withAllOption
        ? [{ account_name: 'CREDIT INVOICE', value: 'credit_invoice' }]
        : undefined
    });

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={fetchData}
      defaultOptions={defaultOptions}
      onFocus={setDefaultResult}
      isLoading={loading}
      isClearable={isClearable ?? true}
      name={name ?? 'bank_account'}
      value={value}
      placeholder={placeholder ?? ''}
      onChange={handleFieldChange}
      classNamePrefix="app-react-select"
      className={`w-100  ${error ? 'is-invalid' : ''}`}
      styles={{
        menuPortal: provided => ({
          ...provided,
          zIndex: 9999
        }),
        menu: provided => ({
          ...provided,
          zIndex: 9999
        }),
        control: provided => ({
          ...provided,
          height: 32,
          minHeight: 32,
          ...style
        })
      }}
    />
  );
};

SelectBankAccount.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  additionalKey: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  isClearable: PropTypes.bool,
  withAllOption: PropTypes.bool
};

export default SelectBankAccount;
