import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ children, variant, onClick, className, ...props }) => {
  const navigate = useNavigate();
  const goBack = e => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <Button
      type="button"
      className={`form-tab-switch-control ${className ? className : ''}`}
      variant={variant ? variant : 'primary'}
      onClick={onClick ? onClick : goBack}
      {...props}
    >
      {children ? children : 'Back'}
    </Button>
  );
};

BackButton.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default BackButton;
