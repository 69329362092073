function isValidDate(date) {
  return date instanceof Date && !isNaN(date.getTime());
}

export default function checkDueDateIsValid(date, dueDate) {
  return new Promise((resolve, reject) => {
    const date1 = new Date(date);
    const date2 = new Date(dueDate);

    if (
      date === null ||
      dueDate === null ||
      !isValidDate(date1) ||
      !isValidDate(date2) ||
      date2 >= date1
    ) {
      resolve('The due date is valid');
      return;
    }

    reject({
      message:
        'The due date must be later than or equal to the transaction date'
    });
  });
}
