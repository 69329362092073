export const chequeStatus = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Bounced',
    value: 'bounced'
  },
  {
    label: 'Cleared',
    value: 'cleared'
  }
];

export const paymentStatus = [
  { label: 'FULLY PAID', value: 'fully_paid' },
  {
    label: 'PARTIALLY PAID',
    value: 'partially_paid'
  },
  { label: 'UN PAID', value: 'un_paid' }
];

export const productType = [
  { label: 'GOODS', value: 'goods' },
  {
    label: 'SERVICE',
    value: 'service'
  },
  { label: 'MANUFACTURED', value: 'manufactured' },
  { label: 'FIXED ASSET', value: 'fixed_asset' }
];
export const fixedAssetType = [
  { label: 'VEHICLE', value: 'vehicle' },
  {
    label: 'MACHINE',
    value: 'machine'
  }
  // { label: 'FURNITURES', value: 'furnitures' },
  // { label: 'OTHERS', value: 'others' }
];
export const ContractTenure = [
  {
    label: 'Daily',
    value: 'daily'
  },
  {
    label: 'Weekly',
    value: 'weekly'
  },
  {
    label: 'Monthly',
    value: 'monthly'
  },
  {
    label: 'Yearly',
    value: 'yearly'
  },
  {
    label: 'Others',
    value: 'others'
  }
];

export const fixedAssetStatus = [
  {
    label: 'Functional',
    value: 'functional',
    color: 'rgb(98, 240, 136)'
  },
  {
    label: 'Non Functional',
    value: 'non_functional',
    color: 'rgb(240, 209, 98)'
  },
  {
    label: 'Under Maintenance',
    value: 'under_maintenance',
    color: 'rgb(58, 171, 242)'
  },
  {
    label: 'Inactive',
    value: 'inactive',
    color: 'rgb(213, 124, 242)'
  },
  {
    label: 'Damaged',
    value: 'damaged',
    color: 'rgb(242, 58, 67)'
  }
];

export const assetStatus = [
  {
    label: 'Rented Out',
    value: 'rented_out'
  },
  {
    label: 'In Stock',
    value: 'in_stock'
  },
  {
    label: 'Allocated',
    value: 'allocated'
  },
  {
    label: 'Not Purchased',
    value: 'not_purchased'
  }
];

export const taxIncluded = [
  {
    label: 'Yes',
    value: '1'
  },
  {
    label: 'No',
    value: '0'
  }
];

export const customerStatus = [
  {
    label: 'Active',
    value: '0'
  },
  {
    label: 'Inactive',
    value: '1'
  }
];

export const documentTypes = [
  {
    label: 'Customer',
    value: 'customer'
  },
  {
    label: 'Supplier',
    value: 'supplier'
  }
];

export const cashHandoverStatus = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Approved',
    value: 'approved'
  },
  {
    label: 'Rejected',
    value: 'rejected'
  }
];
